import { createClient } from 'contentful';
import configuration from 'config';

const contentfulClient = createClient({
  space: configuration.contentful.space,
  accessToken: configuration.contentful.accessToken,
});

export const getContentfulEntries = (typeId) => contentfulClient.getEntries({
  content_type: typeId 
});