export const SIGNUP_PATH = '/sign-up';
export const PROFILE_PATH = '/profile';
export const RESET_PATH = '/reset';
export const HOW_TO_VIDEOS_PATH = '/how-to-videos';
export const NEW_PLANNING_AREA_PATH = '/planning-area/new/data-set/:data_set_uuid';
export const PLANNING_AREA_PATH = '/planning-area/:planning_area_uuid';
export const NEW_SCENARIO_PATH = '/planning-area/:planning_area_uuid/scenario/new';
export const SCENARIO_PATH = '/planning-area/:planning_area_uuid/scenario/:scenario_uuid';
export const COMPARISON_PATH = '/planning-area/:planning_area_uuid/comparison/:comparison_uuid';
export const COMPARISON_SPATIAL_PATH = '/planning-area/:planning_area_uuid/comparison/:comparison_uuid/spatial';
export const SPATIAL_SCENARIO_RUN_PATH = 
  '/planning-area/:planning_area_uuid/comparison/:comparison_uuid/spatial_scenario_run/:spatial_scenario_run_uuid/';
export const SPATIAL_SCENARIO_PATH = 
  '/planning-area/:planning_area_uuid/comparison/:comparison_uuid/scenario/:scenario_uuid/';

export const makePlanningAreaPath = (planningAreaUuid='new', dataSetUuid) => {
  if (planningAreaUuid === 'new') {
    return NEW_PLANNING_AREA_PATH.replace(':data_set_uuid', dataSetUuid);
  }
  return PLANNING_AREA_PATH.replace(':planning_area_uuid', planningAreaUuid);
}
export const makeScenarioPath = (planningAreaUuid, scenarioUuid='new') =>
  SCENARIO_PATH.replace(':planning_area_uuid', planningAreaUuid).replace(':scenario_uuid', scenarioUuid);
export const makeComparisonPath = (planningAreaUuid, comparison_uuid) =>
  COMPARISON_PATH.replace(':planning_area_uuid', planningAreaUuid).replace(':comparison_uuid', comparison_uuid);
export const makeComparisonSpatialPath = (planningAreaUuid, comparison_uuid) =>
  COMPARISON_SPATIAL_PATH.replace(':planning_area_uuid', planningAreaUuid).replace(':comparison_uuid', comparison_uuid);
export const makeSpatialScenarioRunPath = (planningAreaUuid, comparisonUuid, spatialScenarioRunUuid) =>
  SPATIAL_SCENARIO_RUN_PATH.replace(':planning_area_uuid', planningAreaUuid).replace(
    ':comparison_uuid', comparisonUuid
  ).replace(
    ':spatial_scenario_run_uuid', spatialScenarioRunUuid
  );
export const makeSpatialScenarioPath = (planningAreaUuid, comparisonUuid, scenarioUuid) =>
  SPATIAL_SCENARIO_PATH.replace(':planning_area_uuid', planningAreaUuid).replace(
    ':comparison_uuid', comparisonUuid
  )
  .replace(
    ':scenario_uuid', scenarioUuid
  );  