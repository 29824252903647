import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from 'reducers';
import { isDevelopment, isLocal } from 'config';
import { save, load } from 'redux-localstorage-simple';

const LOCALSTORAGE_SETTINGS = { states: ['user.attributes'] };

const configureStore = (initialState = load(LOCALSTORAGE_SETTINGS)) => {
  const middlewares = [];
  middlewares.push(thunk);
  middlewares.push(save(LOCALSTORAGE_SETTINGS));

  let applied = applyMiddleware(...middlewares);
  if (isDevelopment() || isLocal()) {
    applied = composeWithDevTools(applied);
  }

  if (initialState !== null) {
    return createStore(reducers, initialState, applied);
  }

  return createStore(reducers, applied);
};

export default configureStore;
