import { combineReducers } from 'redux';
import user from './user';
import comparison from './comparison';
import planning from './planning';
import application from './application';
import scenario from './scenario';
import dataset from './dataset';
import spatial from './spatial';

export default combineReducers({
  comparison,
  user,
  planning,
  scenario,
  application,
  dataset,
  spatial,
});
