import Amplify, { Auth, Storage } from 'aws-amplify';
import configuration from 'config';

Amplify.configure(configuration.cognito);

export const login = (email, password) => Auth.signIn(email, password);
export const signout = () => Auth.signOut();
export const signup = (signupParams) => Auth.signUp(signupParams);
export const changePassword = async (oldPassword, newPassword) =>
  Auth.changePassword(await Auth.currentAuthenticatedUser(), oldPassword, newPassword);
export const getJWT = async () => (await Auth.currentSession()).getAccessToken().jwtToken;
export const updateAttributes = async (attributes) =>
  Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), attributes);
export const forgotPassword = (email) => Auth.forgotPassword(email);
export const forgotPasswordSubmit = (email, code, password) => Auth.forgotPasswordSubmit(email, code, password);

export const storagePut = (file) => Storage.put(`avatar.png`, file, { level: 'protected', contentType: 'image/png' });
export const storageList = () => Storage.list(`avatar.png`, { level: 'protected' });
export const signedURL = () => Storage.get(`avatar.png`, { level: 'protected', contentType: 'image/png' });
