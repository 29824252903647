import ApiClient from 'util/api-client';

export const listMapLayers = (jwtToken, dataSetUuid, datasetVersionUuid) => 
  ApiClient.get(jwtToken, `/datasets/${dataSetUuid}/versions/${datasetVersionUuid}/map-layers`);
export const computeMapLayers = (jwtToken, geometry, dataSetUuid, datasetVersionUuid) => 
  ApiClient.post(jwtToken, `/datasets/${dataSetUuid}/versions/${datasetVersionUuid}/map-layers`, { geometry });
export const listPlanningAreas = (jwtToken) => ApiClient.get(jwtToken, '/planning-areas');
export const createPlanningArea = (jwtToken, name, geometry, emphasis_areas) =>
  ApiClient.post(jwtToken, '/planning-areas', {
    name,
    description: '',
    geometry,
    emphasis_areas
  });
export const updatePlanningArea = (jwtToken, planningAreaUuid, data) =>
  ApiClient.put(jwtToken, `/planning-areas/${planningAreaUuid}`, data);
export const deletePlanningArea = (jwtToken, planningAreaUuid) =>
  ApiClient.delete(jwtToken, `/planning-areas/${planningAreaUuid}`);
export const listScenarios = (jwtToken, planningAreaUuid) =>
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios/`);
export const listScenarioObjectives = (jwtToken) => ApiClient.get(jwtToken, '/scenario-objectives');

export const generateScenarioRun = (jwtToken, planningAreaUuid, data) =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-runs`, data);
export const getScenarioRun = (jwtToken, planningAreaUuid, scenarioRunUuid) =>
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-runs/${scenarioRunUuid}`);
export const getScenarioRunProjects = (jwtToken, planningAreaUuid, scenarioRunUuid) =>
  ApiClient.get(
    jwtToken,
    `/planning-areas/${planningAreaUuid}/scenario-runs/${scenarioRunUuid}/projects`
  );

export const createScenario = (jwtToken, { name, planningAreaUuid, scenarioRunUuid, description, recreatedFrom }) =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios`, {
    name,
    description: description !== undefined ? description : '',
    scenario_run_uuid: scenarioRunUuid,
    upgraded_from_scenario_uuid: recreatedFrom
  });
export const getScenarioDatasetVersion = (jwtToken, planningAreaUuid, scenarioUuid) =>
  ApiClient.get(
    jwtToken,
    `/planning-areas/${planningAreaUuid}/scenarios/${scenarioUuid}/dataset-version`
  );
export const createOptimizedScenario = (jwtToken, name, planningAreaUuid, scenarioRunUuid, sourceScenarioUuid, description) =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios`, {
    name,
    description: description !== undefined ? description : '',
    scenario_run_uuid: scenarioRunUuid,
    source_scenario_uuid: sourceScenarioUuid,
  });

export const listScenarioComparisons = (jwtToken, planningAreaUuid) =>
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-comparisons`);

export const deleteScenario= (jwtToken, planningAreaUuid, scenarioUuid) =>
  ApiClient.delete(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios/${scenarioUuid}`);
export const getScenarioProjectMetrics = (jwtToken, planningAreaUuid, scenarioRunUuid, projectUuid) =>
  ApiClient.get(
    jwtToken,
    `/planning-areas/${planningAreaUuid}/scenario-runs/${scenarioRunUuid}/projects/${projectUuid}`
  );
export const getPolygonMetrics = (jwtToken, datasetUuid, datasetVersionUuid, unitId) =>
  ApiClient.get(
    jwtToken,
    `/datasets/${datasetUuid}/versions/${datasetVersionUuid}/dataset-polygon/${unitId}`
  );

export const createScenarioComparison = (jwtToken, planningAreaUuid, scenarios, name, description = '') =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-comparisons`, {
    scenarios,
    name,
    description,
  });
export const fetchComparison = (jwtToken, planningAreaUuid, comparisonUuid) =>
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-comparisons/${comparisonUuid}`);
export const deleteComparison = (jwtToken, planningAreaUuid, comparisonUuid) =>
  ApiClient.delete(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-comparisons/${comparisonUuid}`);
export const updateScenarioComparison = (jwtToken, planningAreaUuid, comparisonUuid, name, description = '', scenarios) =>
  ApiClient.put(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-comparisons/${comparisonUuid}`, {
    name,
    description,
    scenarios,
  });
export const listPlanningAreaShares = (jwtToken, planningAreaUuid) => 
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/share`);
export const sendInvite = (jwtToken, planningAreaUuid, email) =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/share`, {
    email
  });
export const unsharePlanningArea= (jwtToken, planningAreaUuid, planningAreaShareId) =>
  ApiClient.delete(jwtToken, `/planning-areas/${planningAreaUuid}/share/${planningAreaShareId}`);  
export const updateScenario = (jwtToken, planningAreaUuid, scenarioUuid, name, description = '') =>
  ApiClient.put(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios/${scenarioUuid}`, {
    name,
    description,
  }); 
export const fetchPlanningAreaObjectives = (jwtToken, planningAreaUuid, scenarioUuid) =>
  ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenarios/${scenarioUuid}/objectives`);
export const startShapeFileExport = (jwtToken, planningAreaUuid, scenarioRunUuid) =>
ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-runs/${scenarioRunUuid}/shapefile`);
export const getShapeFileExportStatus = (jwtToken, planningAreaUuid, scenarioRunUuid, scenarioRunExportUuid) =>
ApiClient.get(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-runs/${scenarioRunUuid}/shapefile/${scenarioRunExportUuid}/state`);
export const listDataSets = (jwtToken) => ApiClient.get(jwtToken, `/datasets/`);
export const searchDataSets = (jwtToken, geometry) => ApiClient.post(jwtToken, '/datasets/search', { geometry });
export const fetchPolygonStatistics = (jwtToken, dataSetUuid, datasetVersionUuid, unitIds) => 
  ApiClient.post(jwtToken, `/datasets/${dataSetUuid}/versions/${datasetVersionUuid}/polygon-statistics`, unitIds);
export const fetchPendingUserAgreement = (jwtToken) =>
  ApiClient.get(jwtToken, `/user-agreements/pending`);
export const fetchAcceptedUserAgreement = (jwtToken) =>
  ApiClient.get(jwtToken, `/user-agreements/accepted`);
export const acceptUserAgreement = (jwtToken, userAgreementUuid) =>
  ApiClient.post(jwtToken, `/user-agreements/${userAgreementUuid}/accept`);
export const createSpatialScenario = (jwtToken, planningAreaUuid, scenariCoomparisonUuid, projects) =>
  ApiClient.post(jwtToken, `/planning-areas/${planningAreaUuid}/scenario-runs/spatial`, {
    scenario_comparison_uuid: scenariCoomparisonUuid, 
    projects,
});