import {
  SET_SCENARIOS,
  SET_SCENARIO_OBJECTIVES,
  SET_NEW_SCENARIO_OBJECTIVE_WEIGHT,
  RESET_NEW_SCENARIO_OBJECTIVE_WEIGHT,
  SET_SCENARIO_RUN,
  RESET_SCENARIO_RUN,
  CLEAR_SCENARIO_RUN,
  SCENARIO_RUN_IN_PROGRESS,
  SCENARIO_RUN_IN_PROGRESS_STOP,
  CHECKING_SCENARIO_RUN_STATES_TIMER_START,
  CHECKING_SCENARIO_RUN_STATES_TIMER_STOP,
  SET_SCENARIO_RUN_RESULTS,
  CREATE_SCENARIO_IN_PROGRESS,
  CREATE_SCENARIO_IN_PROGRESS_STOP,
  SET_CURRENT_SCENARIO,
  SCENARIO_RUN_LOADING_IN_PROGRESS,
  SCENARIO_RUN_LOADING_IN_PROGRESS_STOP,
  DELETE_SCENARIO_IN_PROGRESS,
  SCENARIO_PROJECTS_LOADING_IN_PROGRESS,
  SCENARIO_PROJECTS_LOADING_IN_PROGRESS_STOP,
  SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS,
  SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS_STOP,
  SET_SCENARIO_PROJECT_METRICS,
  SET_CURRENT_SCENARIO_PROJECT_METRICS,
  CURRENT_SCENARIO_OUTDATED,
  SET_CURRENT_POLYGON_METRICS,
  TOGGLE_SIZE_WARNING,
  SET_DESELECTED_PROJECTS,
  SET_RENAME_SCENARIO,
  CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_START,
  CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_STOP,
  SET_SHAPE_FILE_EXPORT_IN_PROGRESS,
  SET_SELECTED_OPTIMIZE_AREAS,
  SET_FEELING_RESILIENT,
  SET_RECREATE_SCENARIO_STATUS,
  SET_FEELING_RESILIENT_SCENARIO_STATUS,
} from 'actions/scenario';

import {
  /* mergeDeepRight, */ lensPath,
  set,
  filter,
  reject,
  propSatisfies,
  isNil,
  prop,
  groupBy,
} from 'ramda';

import { CLEAR_SESSION } from 'actions/user';

export const DEFAULT_STATE = {
  // A dictionary of planning-areas to scenarios -- unfortunately there is no
  // aspect of the 'planning area' record that would tell us whether or not we
  // need to re-fetch a scenarios list, but at least by storing the old values
  // we can show the old list of scenarios while the new API call is made.
  byPlanningArea: {},
  currentScenarioRun: null,
  scenarioRunInProgress: false,
  scenarioRunLoadingInProgress: false,
  checkingScenarioRunStatesTimer: null,
  scenarioObjectives: [],
  newScenarioObjectivesWeight: undefined,
  scenarioRunData: undefined,
  createScenarioInProgress: false,
  currentScenario: null,
  deleteScenarioInProgress: false,
  scenarios: [],
  scenarioProjectsLoadingInProgress: false,
  scenarioProjectMetrics: undefined,
  scenarioProjectMetricsLoadingInProgress: false,
  currentScenarioProjectMetrics: null,
  currentScenarioOutdated: false,
  currentPolygonMetrics: null,
  sizeWarning: false,
  deselectedProjects: [],
  renameScenario: null,
  shapeFileExportStatusTimer: null,
  shapeFileExportUuid: null,
  shapeFileExportInProgress: false,
  selectedOptimizeAreas: null,
  feelingResilient: false,
  recreateScenarioStatus: 'idle',
  feelingResilientScenarioStatus: 'idle'
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SET_SCENARIOS:
      return {
        ...state,
        byPlanningArea: {
          ...state.byPlanningArea,
          [action.payload.uuid]: action.payload.scenarios,
        },
        currentScenarioRun: null,
      };
    case SET_SCENARIO_OBJECTIVES:
      return {
        ...state,
        scenarioObjectives: action.payload.scenarioObjectives,
      };
    case SET_NEW_SCENARIO_OBJECTIVE_WEIGHT:
      const weightLens = lensPath([
        'newScenarioObjectivesWeight',
        action.payload.targetUuid,
        action.payload.objectiveUuid,
        'weight',
      ]);
      return set(weightLens, action.payload.weight)(state);

    // terrcin: or is this more readable? it does the same thing
    // return mergeDeepRight(state)({
    //   newScenarioObjectivesWeight: {
    //     [action.payload.targetUuid]: {
    //       [action.payload.objectiveUuid]: {
    //         weight: action.payload.weight
    //       }
    //     }
    //   }
    // });

    case RESET_NEW_SCENARIO_OBJECTIVE_WEIGHT:
      // We need to completely delete keys in newScenarioObjectivesWeight
      // as we leverage this state to show if any newScenarioObjectiveWeights
      // have been selected in a users session.
      let newNewScenarioObjectivesWeight = state.newScenarioObjectivesWeight;
      newNewScenarioObjectivesWeight && delete newNewScenarioObjectivesWeight[action.payload.targetUuid];
      return {
        ...state,
        newScenarioObjectivesWeight: {
          ...newNewScenarioObjectivesWeight,
        },
      };
    case SET_SCENARIO_RUN:
      return {
        ...state,
        scenarioRunLoadingInProgress: false,
        currentScenarioRun: reformatScenarioRunObjectives(action.payload),
      };
    case RESET_SCENARIO_RUN:
      return {
        ...state,
        currentScenarioRun: {
          ...state.currentScenarioRun,
          refinement_options: null,
        },
      };
    case CLEAR_SCENARIO_RUN:
      return {
        ...state,
        currentScenarioRun: undefined,
      };
    case SCENARIO_RUN_IN_PROGRESS:
      return {
        ...state,
        scenarioRunInProgress: true,
      };
    case SCENARIO_RUN_IN_PROGRESS_STOP:
      return {
        ...state,
        scenarioRunInProgress: false,
      };

    case SET_RECREATE_SCENARIO_STATUS:
      return {
        ...state,
        recreateScenarioStatus: action.payload,
      }

    case SET_FEELING_RESILIENT_SCENARIO_STATUS:
      return {
        ...state,
        feelingResilientScenarioStatus: action.payload,
      }

    case CHECKING_SCENARIO_RUN_STATES_TIMER_START:
      return {
        ...state,
        checkingScenarioRunStatesTimer: action.payload.checkingScenarioRunStatesTimer,
      };
    case CHECKING_SCENARIO_RUN_STATES_TIMER_STOP:
      return {
        ...state,
        checkingScenarioRunStatesTimer: null,
        scenarioRunInProgress: false,
      };
    case SET_SCENARIO_RUN_RESULTS:
      return {
        ...state,
        scenarioRunInProgress: false,
        scenarioProjectsLoadingInProgress: false,
        scenarioRunData: {
          ...state.scenarioRunData,
          [action.payload.planningAreaUuid]: {
            ...state.scenarioRunData?.[action.payload.planningAreaUuid],
            [action.payload.scenarioRunUuid]: {
              projects: action.payload.projects,
            },
          },
        },
      };
    case CREATE_SCENARIO_IN_PROGRESS:
      return {
        ...state,
        createScenarioInProgress: true,
      };
    case CREATE_SCENARIO_IN_PROGRESS_STOP:
      return {
        ...state,
        createScenarioInProgress: false,
      };
    case SET_CURRENT_SCENARIO:

      const currentScenario = action.payload? {...action.payload} : null
      if (currentScenario?.scenario_run)
        currentScenario.scenario_run = reformatScenarioRunObjectives(currentScenario.scenario_run)

      return {
        ...state,
        currentScenario: currentScenario,
        currentScenarioDatasetMetrics: null,
        currentScenarioOutdated: false,
      };
    case SCENARIO_RUN_LOADING_IN_PROGRESS:
      return {
        ...state,
        scenarioRunLoadingInProgress: true,
      };
    case SCENARIO_RUN_LOADING_IN_PROGRESS_STOP:
      return {
        ...state,
        scenarioRunLoadingInProgress: false,
      };
    case DELETE_SCENARIO_IN_PROGRESS:
      return {
        ...state,
        deleteScenarioInProgress: true,
      };
    case SCENARIO_PROJECTS_LOADING_IN_PROGRESS:
      return {
        ...state,
        scenarioProjectsLoadingInProgress: true,
      };
    case SCENARIO_PROJECTS_LOADING_IN_PROGRESS_STOP:
      return {
        ...state,
        scenarioProjectsLoadingInProgress: false,
      };
    case SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS:
      return {
        ...state,
        scenarioProjectMetricsLoadingInProgress: true,
      };
    case SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS_STOP:
      return {
        ...state,
        scenarioProjectMetricsLoadingInProgress: false,
      };
    case SET_SCENARIO_PROJECT_METRICS:
      return {
        ...state,
        scenarioProjectMetricsLoadingInProgress: false,
        scenarioProjectMetrics: {
          ...state.scenarioProjectMetrics,
          [action.payload.planningAreaUuid]: {
            ...state.scenarioProjectMetrics?.[action.payload.planningAreaUuid],
            [action.payload.scenarioRunUuid]: {
              data: action.payload.data,
            },
          },
        },
      };
    case CURRENT_SCENARIO_OUTDATED:
      return {
        ...state,
        currentScenarioOutdated: action.payload,
      };
    case SET_CURRENT_SCENARIO_PROJECT_METRICS:
      return {
        ...state,
        currentScenarioProjectMetrics: action.payload,
      };
    case SET_CURRENT_POLYGON_METRICS:
      return {
        ...state,
        currentPolygonMetrics: action.payload,
      };
    case TOGGLE_SIZE_WARNING:
      return {
        ...state,
        sizeWarning: action.payload,
      };
    case SET_DESELECTED_PROJECTS:
      return {
        ...state,
        deselectedProjects: action.payload,
      };
    case SET_RENAME_SCENARIO:
      return {
        ...state,
        renameScenario: action.payload,
      };
    case CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_START:
      return {
        ...state,
        shapeFileExportStatusTimer: action.payload.shapeFileExportStatusTimer,
        shapeFileExportUuid: action.payload.shapeFileExportUuid,
      };
    case CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_STOP:
      return {
        ...state,
        shapeFileExportStatusTimer: null,
        shapeFileExportUuid: null,
        shapeFileExportInProgress: false,
      };
    case SET_SHAPE_FILE_EXPORT_IN_PROGRESS:
      return {
        ...state,
        shapeFileExportInProgress: action.payload,
      };
    case SET_SELECTED_OPTIMIZE_AREAS:
      return {
        ...state,
        selectedOptimizeAreas: action.payload,
      };
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      };

    case SET_FEELING_RESILIENT:
      return {
        ...state,
        feelingResilient: action.payload,
      };

    default:
      return state;
  }
};

const reformatScenarioRunObjectives = scenarioRun => {
  const emphasisAreaObjectives = reject(propSatisfies(isNil, 'emphasis_area_uuid'))(
    scenarioRun?.objectives || []
  );
  scenarioRun.objectives = filter(propSatisfies(isNil, 'emphasis_area_uuid'))(
    scenarioRun?.objectives || []
  );
  scenarioRun.emphasis_area_objectives = groupBy(prop('emphasis_area_uuid'))(emphasisAreaObjectives);

  return scenarioRun
}

export default reducer;
