import {
  listPlanningAreas,
  computeMapLayers,
  listMapLayers,
  createPlanningArea as createPlanningAreaAPI,
  deletePlanningArea as deletePlanningAreaAPI,
  listPlanningAreaShares,
  sendInvite as sendInviteAPI,
  unsharePlanningArea as unsharePlanningAreaAPI,
  updatePlanningArea as updatePlanningAreaAPI,
  fetchPlanningAreaObjectives as fetchPlanningAreaObjectivesAPI,
} from 'services/api';
import { getJWT } from 'services/auth';
import { showError } from './application';
import { fetchScenarios } from './scenario';
import { fetchComparisons } from './comparison';
import { setCurrentDataSetByGeometry } from './dataset';
import { equals } from 'ramda';
import { sendAnalyticsEvent } from 'util/analytics';

export const SET_PLANNING_AREAS = 'SET_PLANNING_AREAS';
export const SET_ACTIVE_AREA = 'SET_ACTIVE_AREA';
export const SET_MAP_LAYERS = 'SET_MAP_LAYERS';
export const STORE_MAP_LAYERS = 'STORE_MAP_LAYERS';
export const SET_MAP_GROUP_VISIBILITY = 'SET_MAP_GROUP_VISIBILITY';
export const SET_MAP_LAYER_VISIBILITY = 'SET_MAP_LAYER_VISIBILITY';
export const RESET_MAP_LAYER_VISIBILITY = 'RESET_MAP_LAYER_VISIBILITY';
export const SET_CURRENT_PLANNING_AREA = 'SET_CURRENT_PLANNING_AREA';
export const ADD_CURRENT_EMPHASIS_AREA = 'ADD_CURRENT_EMPHASIS_AREA';
export const UPDATE_CURRENT_EMPHASIS_AREA = 'UPDATE_CURRENT_EMPHASIS_AREA';
export const DELETE_CURRENT_EMPHASIS_AREA = 'DELETE_CURRENT_EMPHASIS_AREA';
export const SET_IN_PROGRESS = 'SET_IN_PROGRESS';
export const SET_SHARE_PLANNING_AREA = 'SET_SHARE_PLANNING_AREA';
export const SET_PLANNING_AREA_SHARES = 'SET_PLANNING_AREA_SHARES';
export const SET_RENAME_PLANNING_AREA = 'SET_RENAME_PLANNING_AREA';
export const SET_CURRENT_PLANNING_AREA_OBJECTIVES = 'SET_CURRENT_PLANNING_AREA_OBJECTIVES';
export const TOGGLE_SHAPEFILE_UPLOAD_MODAL = 'TOGGLE_SHAPEFILE_UPLOAD_MODAL'
export const SET_DRAW_CONTROL_MODE = 'SET_DRAW_CONTROL_MODE'
export const SET_DRAW_TYPE = 'SET_DRAW_TYPE'

export const setCurrentPlanningArea = (planningArea) => async (dispatch, getState) => {
  await dispatch({
    type: SET_CURRENT_PLANNING_AREA,
    payload: planningArea,
  });
  sendAnalyticsEvent('open_planning_area');

  const { currentPlanningArea } = getState().planning;

  if (currentPlanningArea?.geometry) {
    await dispatch(setCurrentDataSetByGeometry(currentPlanningArea.geometry));
  } else {
    dispatch(fetchMapLayers());
  }

  if (currentPlanningArea?.uuid) {
    dispatch(fetchScenarios(currentPlanningArea.uuid));

    return dispatch(fetchComparisons(currentPlanningArea.uuid));
  }
};

export const setActiveArea = (activeArea) => async (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_AREA,
    payload: activeArea,
  });
};

export const addCurrentEmphasisArea = (emphasisArea) => async (dispatch) => {
  sendAnalyticsEvent('add_current_emphasis_area');
  return await dispatch({
    type: ADD_CURRENT_EMPHASIS_AREA,
    payload: emphasisArea,
  });
};

export const deleteCurrentEmphasisArea = (emphasisAreaID) => async (dispatch) => {
  sendAnalyticsEvent('delete_current_emphasis_area');
  return await dispatch({
    type: DELETE_CURRENT_EMPHASIS_AREA,
    payload: emphasisAreaID,
  });
};

export const fetchPlanningAreas = () => async (dispatch) => {
  const planningAreas = await listPlanningAreas(await getJWT());
  return dispatch({
    type: SET_PLANNING_AREAS,
    payload: planningAreas.data,
  });
};

export const createPlanningArea = (name, geometry, emphasisAreas = []) => async (dispatch) => {
  await dispatch({ type: SET_IN_PROGRESS, payload: true });
  try {
    const response = await createPlanningAreaAPI(await getJWT(), name, geometry, emphasisAreas);
    sendAnalyticsEvent('create_planning_area');
    dispatch(setCurrentPlanningArea(response.data));
    return dispatch(fetchPlanningAreas());
  } catch (e) {
    dispatch({ type: SET_IN_PROGRESS, payload: false });
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const deletePlanningArea = (planningAreaId) => async (dispatch) => {
  await dispatch({ type: SET_IN_PROGRESS, payload: true });
  try {
    await deletePlanningAreaAPI(await getJWT(), planningAreaId);
    sendAnalyticsEvent('delete_planning_area');
    return dispatch(fetchPlanningAreas());
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};

/** Given a list of reducer storedMapLayers, find its index in the cache. */
export const mapLayersIndex = (storedMapLayers, geometry, planningAreaUuid, datasetVersionUuid) =>
  storedMapLayers.findIndex(
    (ml) =>
      ml.planningAreaUuid === planningAreaUuid &&
      ml.datasetVersionUuid === datasetVersionUuid &&
      equals(ml.geometry, geometry)
  );

export const fetchMapLayers = () => async (dispatch, getState) => {
  const {
    planning: { currentPlanningArea, storedMapLayers },
    dataset: { currentDataSet, currentDataSetVersionUuid },
  } = getState();

  const planningAreaUuid = currentPlanningArea?.uuid || null;
  const geometry = currentPlanningArea?.geometry || null;
  const currentDataSetUuid = currentDataSet?.uuid || null;
  const datasetVersionUuid = currentDataSetVersionUuid;

  // Don't refetch if it already exists in the state - we don't need to
  // refetch these kinds of values - they take a long time and don't change.
  const geometryIndex = mapLayersIndex(
    storedMapLayers,
    geometry,
    planningAreaUuid,
    datasetVersionUuid
  );
  if (geometryIndex >= 0) {
    return dispatch(updateCurrentMapLayer());
  }

  // provide some placeholder data while we load the new dataset, and prevent
  // future API calls for this geometry:
  await dispatch({
    type: STORE_MAP_LAYERS,
    payload: {
      geometry,
      planningAreaUuid,
      datasetVersionUuid,
      mapLayers: { categories: [] },
    },
  });

  if (!currentDataSetUuid) return;

  let mapLayers;
  if (geometry) {
    mapLayers = await computeMapLayers(
      await getJWT(),
      geometry,
      currentDataSetUuid,
      datasetVersionUuid
    );
  } else {
    mapLayers = await listMapLayers(await getJWT(), currentDataSetUuid, datasetVersionUuid);
  }

  await dispatch({
    type: STORE_MAP_LAYERS,
    payload: {
      geometry,
      planningAreaUuid,
      datasetVersionUuid,
      mapLayers: mapLayers?.data,
    },
  });

  return dispatch(updateCurrentMapLayer());
};

export const updateCurrentMapLayer = () => async (dispatch, getState) => {
  // When there is no mapLayers value for currentPlanningArea.geometry, use the
  // default one for the whole area:
  const {
    planning: { currentPlanningArea, currentPlanningArea: geometry, storedMapLayers },
    dataset: { currentDataSetVersionUuid },
  } = getState();
  if (storedMapLayers.length === 0) return;

  const emptyGeometryIndex = mapLayersIndex(storedMapLayers, null);
  const geometryIndex = mapLayersIndex(
    storedMapLayers,
    geometry?.geometry || null,
    currentPlanningArea?.uuid || null,
    currentDataSetVersionUuid
  );
  let mapLayers = geometryIndex >= 0 ? storedMapLayers[geometryIndex].mapLayers : null;

  if (!mapLayers) {
    mapLayers = [];
    if (emptyGeometryIndex >= 0) {
      mapLayers = storedMapLayers[emptyGeometryIndex].mapLayers;
    }
  }

  return dispatch({
    type: SET_MAP_LAYERS,
    payload: mapLayers,
  });
};

export const setMapGroupVisibility = (categoryUuid, mapGroupUuid) => (dispatch, getState) => {
  const datasetUuid = getState().dataset?.currentDataSet?.uuid;

  dispatch({
    type: SET_MAP_GROUP_VISIBILITY,
    payload: {
      datasetUuid,
      categoryUuid,
      mapGroupUuid,
    },
  });
};

export const setPlanningAreaToDatasetBoundary = () => async (dispatch, getState) => {
  const { currentDataSet } = getState().dataset;
  const { currentPlanningArea } = getState().planning;
  return dispatch({
    type: SET_CURRENT_PLANNING_AREA,
    payload: {
      ...currentPlanningArea,
      geometry: currentDataSet.boundary,
    },
  });
};

export const setMapLayerVisibility = (layerUuid, opacity, visible) => (dispatch, getState) => {
  const datasetUuid = getState().dataset?.currentDataSet?.uuid;

  dispatch({
    type: SET_MAP_LAYER_VISIBILITY,
    payload: {
      datasetUuid,
      layerUuid,
      opacity,
      visible,
    },
  });
};

export const resetMapLayerVisibility = () => (dispatch, getState) => {
  const datasetUuid = getState().dataset?.currentDataSet?.uuid;

  dispatch({
    type: RESET_MAP_LAYER_VISIBILITY,
    payload: datasetUuid,
  });
};

export const setSharePlanningArea = (planningArea) => async (dispatch) => {
  await dispatch({
    type: SET_SHARE_PLANNING_AREA,
    payload: planningArea,
  });
  if (planningArea?.uuid) {
    return dispatch(fetchPlanningAreaShares(planningArea.uuid));
  }
};

export const fetchPlanningAreaShares = (planningAreaUuid) => async (dispatch) => {
  try {
    const planningAreaShares = await listPlanningAreaShares(await getJWT(), planningAreaUuid);
    return dispatch({
      type: SET_PLANNING_AREA_SHARES,
      payload: planningAreaShares.data,
    });
  } catch (e) {
    dispatch(setSharePlanningArea(null));
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const sendInvite = (planningAreaUuid, email) => async (dispatch) => {
  try {
    await sendInviteAPI(await getJWT(), planningAreaUuid, email);
    sendAnalyticsEvent('send_sharing_invite');
    return dispatch(setSharePlanningArea(null));
  } catch (e) {
    dispatch(setSharePlanningArea(null));
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const unsharePlanningArea = (planningAreaId, planningAreaShareId) => async (dispatch) => {
  try {
    await unsharePlanningAreaAPI(await getJWT(), planningAreaId, planningAreaShareId);
    sendAnalyticsEvent('unshare_planning_area');
    return dispatch(fetchPlanningAreaShares(planningAreaId));
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const setRenamePlanningArea = (area = null) => ({
  type: SET_RENAME_PLANNING_AREA,
  payload: area,
});
export const updatePlanningArea = (data) => async (dispatch, getState) => {
  try {
    await updatePlanningAreaAPI(
      await getJWT(),
      getState().planning.renamePlanningArea.uuid,
      data
    );
    dispatch(setRenamePlanningArea(null));
    await dispatch(fetchPlanningAreas());
    return dispatch({
      type: SET_CURRENT_PLANNING_AREA,
      payload: getState().planning?.planningAreas.find(
        (pa) => pa?.uuid === getState().planning?.currentPlanningArea?.uuid
      ),
    });
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};
export const setCurrentPlanningAreaObjectives = (objectives = null) => ({
  type: SET_CURRENT_PLANNING_AREA_OBJECTIVES,
  payload: objectives,
});

export const fetchPlanningAreaObjectives = (scenarioUuid, planningAreaUuid) => async (
  dispatch,
  getState
) => {
  try {
    const planningPlanningAreaObjectives = await fetchPlanningAreaObjectivesAPI(
      await getJWT(),
      planningAreaUuid,
      scenarioUuid
    );
    return dispatch(
      setCurrentPlanningAreaObjectives(planningPlanningAreaObjectives?.data?.objectives)
    );
  } catch (e) {
    dispatch(setCurrentPlanningAreaObjectives(null));
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const setDrawControlMode = (mode) => ({
  type: SET_DRAW_CONTROL_MODE,
  payload: mode
});

export const toggleShapefileUploadModal = () => ({
  type: TOGGLE_SHAPEFILE_UPLOAD_MODAL,
});

export const setDrawType = (type) => ({
  type: SET_DRAW_TYPE,
  payload: type
});

