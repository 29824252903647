import { 
  SPATIAL_PROJECTS_LOADING_IN_PROGRESS,
  SPATIAL_PROJECTS_LOADING_IN_PROGRESS_STOP,
  SET_SPATIAL_CONSENSUS_STATISTICS,
  TOGGLE_STATISTICS_LOADING
} from 'actions/spatial';

import { CLEAR_SESSION } from 'actions/user';

const DEFAULT_STATE = {
  bySpatialConsensus: {},
  spatialProjectsLoadingInProgress: false,
  statisticsLoading: false
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SPATIAL_PROJECTS_LOADING_IN_PROGRESS:
      return {
        ...state,
        spatialProjectsLoadingInProgress: true,
      };
    case SPATIAL_PROJECTS_LOADING_IN_PROGRESS_STOP:
      return {
        ...state,
        spatialProjectsLoadingInProgress: false,
      };
    case SET_SPATIAL_CONSENSUS_STATISTICS:
      return {
        ...state,
        bySpatialConsensus: {
          ...state.bySpatialConsensus,
          [action.payload.scenariosUuids]: action.payload.data,
        },
      }; 
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      };

    case TOGGLE_STATISTICS_LOADING:
      return {
        ...state,
        statisticsLoading: !state.statisticsLoading
      }
    default:
      return state;
  }
};

export default reducer;