import {
  listScenarioComparisons,
  getScenarioDatasetVersion,
  createScenarioComparison as createScenarioComparisonApi,
  fetchComparison as fetchComparisonApi,
  deleteComparison as deleteComparisonApi,
  updateScenarioComparison as updateScenarioComparisonApi,
} from 'services/api';
import { getJWT } from 'services/auth';
import { showError } from './application';
import { sendAnalyticsEvent } from 'util/analytics';
import { fetchPlanningAreaObjectives } from './planning';

export const SET_COMPARISONS = 'SET_COMPARISONS';
export const SET_COMPARISONS_BUILDER_VISIBILITY = 'SET_COMPARISONS_BUILDER_VISIBILITY';
export const CREATE_SCENARIO_COMPARISON = 'CREATE_SCENARIO_COMPARISON';
export const CREATE_SCENARIO_COMPARISON_IN_PROGRESS = 'CREATE_SCENARIO_COMPARISON_IN_PROGRESS';
export const CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP =
  'CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP';
export const SET_NEW_COMPARISON_SCENARIOS = 'SET_NEW_COMPARISON_SCENARIOS';
export const SET_CURRENT_COMPARISON = 'SET_CURRENT_COMPARISON';
export const COMPARISON_LOADING_IN_PROGRESS = 'COMPARISON_LOADING_IN_PROGRESS';
export const COMPARISON_LOADING_IN_PROGRESS_STOP = 'COMPARISON_LOADING_IN_PROGRESS_STOP';
export const SET_SELECTED_OBJECTIVE_INDEX = 'SET_SELECTED_OBJECTIVE_INDEX';
export const SET_CURRENT_YEAR_INDEX = 'SET_CURRENT_YEAR_INDEX';
export const SET_CURRENT_SCENARIO_INDEX = 'SET_CURRENT_SCENARIO_INDEX';
export const SET_COMPARISONS_NAME_EDITOR_VISIBILITY = 'SET_COMPARISONS_NAME_EDITOR_VISIBILITY';
export const CURRENT_COMPARISON_OUTDATED = 'CURRENT_COMPARISON_OUTDATED';
export const UPDATE_SCENARIO_COMPARISON_IN_PROGRESS = 'UPDATE_SCENARIO_COMPARISON_IN_PROGRESS';
export const UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP =
  'UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP';
export const SET_NEW_COMPARISON_NAME_AND_DESCRIPTION = 'SET_NEW_COMPARISON_NAME_AND_DESCRIPTION';

export const comparisonsErrorMessage =
  'An error occurred loading Scenario Comparisons. Please try refreshing the page.';
export const comparisonErrorMessage =
  'An error occurred loading Scenario Comparison. Please try refreshing the page.';
export const comparisonDatasetVersionErrorMessage =
  'An error occurred loading Scenario Dataset Versions. Please try refreshing the page.';

const UNTITLEDCOMPARISONNAME = 'Untitled Comparison';

export const fetchComparisons = (planningAreaUuid) => async (dispatch) => {
  let comparisons = [];

  try {
    comparisons = (await listScenarioComparisons(await getJWT(), planningAreaUuid)).data;
  } catch (err) {
    return dispatch(showError(comparisonsErrorMessage));
  }

  return dispatch({
    type: SET_COMPARISONS,
    payload: {
      uuid: planningAreaUuid,
      comparisons,
    },
  });
};

export const fetchComparison = (planningAreaUuid, comparisonUuid) => async (dispatch, getState) => {
  dispatch(setCurrentComparison(null));
  dispatch({ type: COMPARISON_LOADING_IN_PROGRESS });
  try {
    const comparison = (await fetchComparisonApi(await getJWT(), planningAreaUuid, comparisonUuid))
      .data;

    await dispatch(setCurrentComparison(comparison));

    dispatch(fetchComparisonDatasetVersion(planningAreaUuid, comparisonUuid));

    if (getState()?.planning?.currentPlanningAreaObjectives === null) {
      const firstScenarioUuid = comparison.scenarios[0].uuid
      return dispatch(fetchPlanningAreaObjectives(firstScenarioUuid, planningAreaUuid));
    }
  } catch (err) {
    dispatch({ type: COMPARISON_LOADING_IN_PROGRESS_STOP });
    return dispatch(showError(comparisonErrorMessage));
  }
};

export const fetchComparisonDatasetVersion = (planningAreaUuid) => async (dispatch, getState) => {
  const comparison = getState().comparison.currentComparison;

  try {
    const jwt = await getJWT();
    const datasetVersions = await Promise.all(
      comparison.scenarios.map(
        async (scenario) => await getScenarioDatasetVersion(jwt, planningAreaUuid, scenario.uuid)
      )
    );

    return dispatch({
      type: CURRENT_COMPARISON_OUTDATED,
      payload: !datasetVersions.every(dv => dv?.data?.is_active),
    });
  } catch (err) {
    dispatch({
      type: CURRENT_COMPARISON_OUTDATED,
      payload: false,
    });
    return dispatch(showError(comparisonDatasetVersionErrorMessage));
  }
};

export const addScenarioToComparison = (scenario) => async (dispatch, getState) => {
  let scenarios = [scenario].concat(getState()?.comparison?.newComparisonScenarios);

  return dispatch({
    type: SET_NEW_COMPARISON_SCENARIOS,
    payload: scenarios,
  });
};

export const removeScenarioFromComparison = (scenario) => async (dispatch, getState) => {
  let scenarios = [].concat(getState()?.comparison?.newComparisonScenarios);
  scenarios = scenarios.filter((s) => s.uuid !== scenario.uuid);

  return dispatch({
    type: SET_NEW_COMPARISON_SCENARIOS,
    payload: scenarios,
  });
};

export const showComparisonsBuilder = () => ({
  type: SET_COMPARISONS_BUILDER_VISIBILITY,
  payload: true,
});

export const hideComparisonsBuilder = () => ({
  type: SET_COMPARISONS_BUILDER_VISIBILITY,
  payload: false,
});

export const createScenarioComparison = (
  planningAreaUuid,
  comparisons,
  openComparisonPage
) => async (dispatch, getState) => {
  dispatch({ type: CREATE_SCENARIO_COMPARISON_IN_PROGRESS });
  const { newComparisonNameAndDescription } = getState().comparison;
  let scenarios = comparisons.map((c, i) => ({ uuid: c.uuid, sequence: i + 1 }));
  let comparisonName = UNTITLEDCOMPARISONNAME;
  let comparisonDescription = '';

  newComparisonNameAndDescription?.forEach((element) => {
    if (element.name[0] === 'name') {
      comparisonName = element.value;
    }
    if (element.name[0] === 'description') {
      comparisonDescription = element.value;
    }
  });

  try {
    const response = await createScenarioComparisonApi(
      await getJWT(),
      planningAreaUuid,
      scenarios,
      comparisonName,
      comparisonDescription
    );
    sendAnalyticsEvent('create_scenario_comparison', {
      scenario_count: comparisons.length,
    });

    dispatch({ type: CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP });
    dispatch(fetchComparisons(planningAreaUuid));
    dispatch(setNewComparisonNameAndDescription(null));
    openComparisonPage(response?.data?.uuid);
  } catch (e) {
    dispatch({ type: CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP });
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const setCurrentComparison = (comparison) => async (dispatch) => {
  sendAnalyticsEvent('open_scenario_comparison');
  return await dispatch({
    type: SET_CURRENT_COMPARISON,
    payload: comparison,
  });
};

export const setCurrentObjectiveIndex = (index) => ({
  type: SET_SELECTED_OBJECTIVE_INDEX,
  payload: index,
});
export const setCurrentYearIndex = (index) => ({
  type: SET_CURRENT_YEAR_INDEX,
  payload: index,
});
export const setCurrentScenarioIndex = (index) => {
  sendAnalyticsEvent('comparison_table_scenario_selected');
  return {
    type: SET_CURRENT_SCENARIO_INDEX,
    payload: index,
  };
};

export const deleteComparison = (planningAreaId, comparisonUuid, goToHome) => async (dispatch) => {
  try {
    await deleteComparisonApi(await getJWT(), planningAreaId, comparisonUuid);
    sendAnalyticsEvent('delete_scenario_comparison');
    if (goToHome) {
      goToHome();
    }
    dispatch(setCurrentComparison(null));
    return dispatch(fetchComparisons(planningAreaId));
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const openComparisonNameEditor = (comparison) => async (dispatch) => {
  if (typeof comparison !== 'undefined') {
    dispatch(setCurrentComparison(comparison));
  }
  return await dispatch({
    type: SET_COMPARISONS_NAME_EDITOR_VISIBILITY,
    payload: true,
  });
};

export const closeComparisonNameEditor = () => ({
  type: SET_COMPARISONS_NAME_EDITOR_VISIBILITY,
  payload: false,
});
export const updateScenarioComparison = (
  planningAreaUuid,
  comparisons,
  name,
  description
) => async (dispatch) => {
  dispatch({ type: UPDATE_SCENARIO_COMPARISON_IN_PROGRESS });
  let scenarios = comparisons?.scenarios?.map((s) => ({ uuid: s.uuid, sequence: s.sequence }));
  try {
    await updateScenarioComparisonApi(
      await getJWT(),
      planningAreaUuid,
      comparisons?.uuid,
      name,
      description,
      scenarios
    );
    sendAnalyticsEvent('update_scenario_comparison');

    dispatch({ type: UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP });
    dispatch(fetchComparisons(planningAreaUuid));
    return dispatch(fetchComparison(planningAreaUuid, comparisons?.uuid));
  } catch (e) {
    dispatch({ type: UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP });
    return dispatch(showError(e?.response?.data?.message));
  }
};
export const setNewComparisonNameAndDescription = (data) => {
  return {
    type: SET_NEW_COMPARISON_NAME_AND_DESCRIPTION,
    payload: data,
  };
};
