import axios from 'axios';
import config from 'config';

/**
 * An HTTP client for the platform-api service.
 */
export default class ApiClient {
  static get(jwtToken, url, options) {
    return this.getInstance({ jwtToken }).get(url, options);
  }

  static post(jwtToken, url, data = {}) {
    return this.getInstance({ jwtToken }).post(url, data);
  }

  static postForm(jwtToken, url, formFields) {
    const data = new window.FormData();
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in formFields) {
      data.set(prop, formFields[prop]);
    }

    return this.getInstance({ jwtToken, contentType: 'multipart/form-data' }).post(url, data);
  }

  static put(jwtToken, url, data = {}) {
    return this.getInstance({ jwtToken }).put(url, data);
  }

  static delete(jwtToken, url) {
    return this.getInstance({ jwtToken }).delete(url);
  }

  static getInstance({
    timeout = config.api.defaultTimeout,
    jwtToken = '',
    apiKey = config.api.application_key,
    contentType = 'application/json',
  }) {
    const headers = {
      'Content-Type': contentType,
    };

    if (jwtToken !== null) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    if (apiKey !== null) {
      headers['VibrantPlanet-API-Key'] = apiKey;
    }

    const apiInstance = axios.create({
      baseURL: `${config.api.baseUrl}/${config.api.version}`,
      timeout,
      headers,
    });

    return apiInstance;
  }
}
