import { fetchScenarioProjects, scenarioProjectsLoadinFailedErrorMessage, fetchLatestScenarioDatasetVersion } from './scenario';
import { showError } from './application';
import {
    fetchPolygonStatistics as fetchPolygonStatisticsApi,
} from 'services/api';
import { getJWT } from 'services/auth';

export const SPATIAL_PROJECTS_LOADING_IN_PROGRESS = 'SPATIAL_PROJECTS_LOADING_IN_PROGRESS';
export const SPATIAL_PROJECTS_LOADING_IN_PROGRESS_STOP = 'SPATIAL_PROJECTS_LOADING_IN_PROGRESS_STOP';
export const SET_SPATIAL_CONSENSUS_STATISTICS = 'SET_SPATIAL_CONSENSUS_STATISTICS';
export const TOGGLE_STATISTICS_LOADING = 'TOGGLE_STATISTICS_LOADING';

export const fetchPolygonStatisticsErrorMessage = "An error occurred when loading the Polygon Statistics. Please try again.";

export const fetchSpatialComparisonScenariosProjects = (
        planningAreaUuid, comparisonScenarios, scenarios,
    ) => async (dispatch, getState) => {
    const { scenarioRunData } = getState().scenario;
    const { spatialProjectsLoadingInProgress } = getState().spatial;

    if (spatialProjectsLoadingInProgress) {
        return;
    }
    
    dispatch({
        type: SPATIAL_PROJECTS_LOADING_IN_PROGRESS,
    });

    await dispatch(fetchLatestScenarioDatasetVersion(planningAreaUuid, comparisonScenarios));

    try {
        let dispatchWait = comparisonScenarios?.map((scenario)=>{
            let scenarioRunUuid = scenarios?.find((s) => s?.uuid === scenario.uuid )?.scenario_run?.uuid;

            if (scenarioRunUuid && typeof scenarioRunData?.[planningAreaUuid]?.[scenarioRunUuid]?.projects === "undefined") {
                return dispatch(fetchScenarioProjects(planningAreaUuid, scenarioRunUuid));
            }
            return null;
        })
        await Promise.all(dispatchWait);
    } catch (err) {
        dispatch(showError(scenarioProjectsLoadinFailedErrorMessage));
    }
    
    return await dispatch({
        type: SPATIAL_PROJECTS_LOADING_IN_PROGRESS_STOP,
    });
};

// It is weird to group many dispatch call using Promise.all. The right way to proceed could be add behavior
//  to the database to return multiple data at once and stop using .map to group dispatch functions
export const fetchScenariosPolygonStatistics = (consensusUnitIds, scenariosUuids) => async (dispatch, getState) => {
    const { currentDataSet = null } = getState().dataset;
    const data = []

    dispatch({ type: TOGGLE_STATISTICS_LOADING })

    try {
        for (let i = 0; i < consensusUnitIds.length; i++) {
            data.push((
              await fetchPolygonStatisticsApi(
                await getJWT(),
                currentDataSet?.uuid,
                currentDataSet?.active_version?.uuid,
                consensusUnitIds[i]
              )
            )?.data)
        }
    } catch (e) {
        dispatch(showError(fetchPolygonStatisticsErrorMessage));
    }


    dispatch({ type: TOGGLE_STATISTICS_LOADING })
    dispatch({
        type: SET_SPATIAL_CONSENSUS_STATISTICS,
        payload: {
            scenariosUuids: scenariosUuids,
            data: data,
        },
    });
}