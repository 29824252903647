import { 
  UPDATE_USER, 
  UPDATE_AVATAR, 
  IN_PROGRESS, 
  CLEAR_SESSION, 
  RESET_MESSAGE_SENT, 
  FAILED, 
  RESET_ERROR_MESSAGE,
  SET_PASSWORD_CHANGED_POPUP_VISIBILITY, 
} from 'actions/user';

const DEFAULT_STATE = {
  authInProgress: false,
  authError: null,
  messageSent: null,
  attributes: null,
  imgURL: null,
  isPasswordChangedPopupVisible: false,
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case UPDATE_USER:
      return {
        ...DEFAULT_STATE,
        attributes: action.payload,
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        imgURL: action.payload.imgURL,
      };
    case IN_PROGRESS:
      return {
        ...state,
        authInProgress: true,
        authError: null,
      };
    case FAILED:
      return {
        ...state,
        authInProgress: false,
        authError: action.payload,
      };
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      };
    case RESET_MESSAGE_SENT:
      return {
        ...DEFAULT_STATE,
        messageSent: true,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        authError: null,
      };
    case SET_PASSWORD_CHANGED_POPUP_VISIBILITY:
      return {
        ...state,
        isPasswordChangedPopupVisible: action.payload,
      };      
    default:
      return state;
  }
};

export default reducer;
