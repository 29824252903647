import { 
  SET_COMPARISONS,
  SET_COMPARISONS_BUILDER_VISIBILITY,
  CREATE_SCENARIO_COMPARISON_IN_PROGRESS,
  CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP,
  SET_NEW_COMPARISON_SCENARIOS,
  SET_CURRENT_COMPARISON,
  COMPARISON_LOADING_IN_PROGRESS,
  COMPARISON_LOADING_IN_PROGRESS_STOP,
  SET_SELECTED_OBJECTIVE_INDEX,
  SET_CURRENT_YEAR_INDEX,
  SET_CURRENT_SCENARIO_INDEX,
  SET_COMPARISONS_NAME_EDITOR_VISIBILITY,
  UPDATE_SCENARIO_COMPARISON_IN_PROGRESS,
  UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP,
  SET_NEW_COMPARISON_NAME_AND_DESCRIPTION,
  CURRENT_COMPARISON_OUTDATED
} from 'actions/comparison';

import { CLEAR_SESSION } from 'actions/user';

const DEFAULT_STATE = {
  // A dictionary of planning-areas to comparisons
  byPlanningArea: {},
  isComparisonsBuilderVisibile: false,
  createScenarioComparisonInProgress: false,
  newComparisonScenarios: [],
  currentComparison: null,
  currentComparisonOutdated: false,
  comparisonLoadingInProgress: false,
  currentYearIndex: 2,
  currentScenarioIndex: 0,
  selectedObjectiveIndex: 1,
  isComparisonNameEditorVisibile: false,
  updateScenarioComparisonInProgress: false,
  newComparisonNameAndDescription: null,
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SET_COMPARISONS:
      return {
        ...state,
        byPlanningArea: {
          ...state.byPlanningArea,
          [action.payload.uuid]: action.payload.comparisons
        },
      };
    case SET_NEW_COMPARISON_SCENARIOS:
      return {
        ...state,
        newComparisonScenarios: action.payload,
      };      
    case SET_COMPARISONS_BUILDER_VISIBILITY:
      return {
        ...state,
        isComparisonsBuilderVisibile: action.payload,
        newComparisonScenarios: []
      };
    case CREATE_SCENARIO_COMPARISON_IN_PROGRESS:
      return {
        ...state,
        isComparisonsBuilderVisibile: false,
        createScenarioComparisonInProgress: true,
        currentYearIndex: DEFAULT_STATE.currentYearIndex,
        currentScenarioIndex: DEFAULT_STATE.currentScenarioIndex,
        selectedObjectiveIndex: DEFAULT_STATE.selectedObjectiveIndex,
      };
    case SET_CURRENT_COMPARISON:
      return {
        ...state,
        currentComparisonOutdated: false,
        comparisonLoadingInProgress: false,
        currentComparison: action.payload ? { ...action.payload } : null,
      };        
    case CREATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP:
      return {
        ...state,
        createScenarioComparisonInProgress: false
      }; 
    case COMPARISON_LOADING_IN_PROGRESS:
      return {
        ...state,
        comparisonLoadingInProgress: true
      };
    case COMPARISON_LOADING_IN_PROGRESS_STOP:
      return {
        ...state,
        comparisonLoadingInProgress: false
      };
    case SET_SELECTED_OBJECTIVE_INDEX:
      return {
        ...state,
        selectedObjectiveIndex: action.payload     
      };    
    case SET_CURRENT_YEAR_INDEX:
      return {
        ...state,
        currentYearIndex: action.payload     
      };
    case SET_CURRENT_SCENARIO_INDEX:
      return {
        ...state,
        currentScenarioIndex: action.payload     
      };
    case CURRENT_COMPARISON_OUTDATED:
      return {
        ...state,
        currentComparisonOutdated: action.payload     
      }; 
    case SET_COMPARISONS_NAME_EDITOR_VISIBILITY:
      return {
        ...state,
        isComparisonNameEditorVisibile: action.payload     
      }; 
    case UPDATE_SCENARIO_COMPARISON_IN_PROGRESS:
      return {
        ...state,
        isComparisonNameEditorVisibile:false,
        updateScenarioComparisonInProgress: true
      };
    case UPDATE_SCENARIO_COMPARISON_IN_PROGRESS_STOP:
      return {
        ...state,
        updateScenarioComparisonInProgress: false
      };
    case SET_NEW_COMPARISON_NAME_AND_DESCRIPTION:
      return {
        ...state,
        newComparisonNameAndDescription: action.payload
      };
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      }; 
    default:
      return state;
  }
};

export default reducer;
