import fileDownload from 'js-file-download';
import {
  listScenarios,
  listScenarioObjectives,
  generateScenarioRun,
  getScenarioRun,
  getScenarioRunProjects,
  getScenarioDatasetVersion,
  createScenario as createScenarioAPI,
  createOptimizedScenario as createOptimizedScenarioAPI,
  deleteScenario as deleteScenarioAPI,
  updateScenario as updateScenarioAPI,
  getScenarioProjectMetrics,
  getPolygonMetrics,
  startShapeFileExport as startShapeFileExportAPI,
  getShapeFileExportStatus as getShapeFileExportStatusAPI,
  createSpatialScenario as createSpatialScenarioAPI,
} from 'services/api';
import { getJWT } from 'services/auth';
import { showError } from './application';
import { isProduction, isLocal } from 'config';
import { sendAnalyticsEvent } from 'util/analytics';
import { fetchPlanningAreaObjectives, fetchMapLayers } from './planning';
import { fetchComparisons } from './comparison';
import { setCurrentDataSetVersionUuid } from './dataset';
import { valueOfDateTime } from 'components/util/time';

export const SET_SCENARIOS = 'SET_SCENARIOS';
export const SET_SCENARIO_OBJECTIVES = 'SET_SCENARIO_OBJECTIVES';
export const SET_NEW_SCENARIO_OBJECTIVE_WEIGHT = 'SET_NEW_SCENARIO_OBJECTIVE_WEIGHT';
export const RESET_NEW_SCENARIO_OBJECTIVE_WEIGHT = 'RESET_NEW_SCENARIO_OBJECTIVE_WEIGHT';
export const SET_SCENARIO_RUN = 'SET_SCENARIO_RUN';
export const RESET_SCENARIO_RUN = 'RESET_SCENARIO_RUN';
export const CLEAR_SCENARIO_RUN = 'CLEAR_SCENARIO_RUN';
export const CHECKING_SCENARIO_RUN_STATES_TIMER_START = 'CHECKING_SCENARIO_RUN_STATES_TIMER_START';
export const CHECKING_SCENARIO_RUN_STATES_TIMER_STOP = 'CHECKING_SCENARIO_RUN_STATES_TIMER_STOP';
export const SCENARIO_RUN_IN_PROGRESS = 'SCENARIO_RUN_IN_PROGRESS';
export const SET_RECREATE_SCENARIO_STATUS = 'SET_RECREATE_SCENARIO_STATUS';
export const SET_FEELING_RESILIENT_SCENARIO_STATUS = 'SET_FEELING_RESILIENT_SCENARIO_STATUS';
export const SCENARIO_RUN_IN_PROGRESS_STOP = 'SCENARIO_RUN_IN_PROGRESS_STOP';
export const SET_SCENARIO_RUN_RESULTS = 'SET_SCENARIO_RUN_RESULTS';
export const CREATE_SCENARIO_IN_PROGRESS = 'CREATE_SCENARIO_IN_PROGRESS';
export const CREATE_SCENARIO_IN_PROGRESS_STOP = 'CREATE_SCENARIO_IN_PROGRESS_STOP';
export const SET_CURRENT_SCENARIO = 'SET_CURRENT_SCENARIO';
export const SCENARIO_RUN_LOADING_IN_PROGRESS = 'SCENARIO_RUN_LOADING_IN_PROGRESS';
export const SCENARIO_RUN_LOADING_IN_PROGRESS_STOP = 'SCENARIO_RUN_LOADING_IN_PROGRESS_STOP';
export const DELETE_SCENARIO_IN_PROGRESS = 'DELETE_SCENARIO_IN_PROGRESS';
export const SCENARIO_PROJECTS_LOADING_IN_PROGRESS = 'SCENARIO_PROJECTS_LOADING_IN_PROGRESS';
export const SCENARIO_PROJECTS_LOADING_IN_PROGRESS_STOP =
  'SCENARIO_PROJECTS_LOADING_IN_PROGRESS_STOP';
export const SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS =
  'SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS';
export const SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS_STOP =
  'SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS_STOP';
export const SET_SCENARIO_PROJECT_METRICS = 'SET_SCENARIO_PROJECT_METRICS';
export const SET_CURRENT_SCENARIO_PROJECT_METRICS = 'SET_CURRENT_SCENARIO_PROJECT_METRICS';
export const CURRENT_SCENARIO_OUTDATED = 'CURRENT_SCENARIO_OUTDATED';
export const SET_CURRENT_POLYGON_METRICS = 'SET_CURRENT_POLYGON_METRICS';
export const TOGGLE_SIZE_WARNING = 'TOGGLE_SIZE_WARNING';
export const SET_DESELECTED_PROJECTS = 'SET_DESELECTED_PROJECTS';
export const SET_RENAME_SCENARIO = 'SET_RENAME_SCENARIO';
export const CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_START =
  'CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_START';
export const CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_STOP =
  'CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_STOP';
export const SET_SHAPE_FILE_EXPORT_IN_PROGRESS = 'SET_SHAPE_FILE_EXPORT_IN_PROGRESS';
export const SET_SELECTED_OPTIMIZE_AREAS = 'SET_SELECTED_OPTIMIZE_AREAS';
export const SET_FEELING_RESILIENT = 'SET_FEELING_RESILIENT';
export const scenarioErrorMessage =
  'An error occurred loading Scenarios. Please try refreshing the page.';
export const scenarioObjectivesErrorMessage =
  'An error occurred loading Scenario Objectives. Please try refreshing the page.';
export const scenarioDatasetVersionErrorMessage =
  'An error occurred when loading the Scenario Dataset Version. Please try again.';
export const scenarioRunErrorMessage =
  'An error occurred when generating a Scenario. Please try again.';
export const scenarioRunFailedErrorMessage =
  'An error occurred when running a Scenario. Please try again.';
export const scenarioProjectsLoadinFailedErrorMessage =
  'An error occurred when loading a Scenario Projects. Please try again.';
export const showPolygonMetricsPopUpErrorMessage =
  'An error occurred when loading a Metrics. Please try again.';
export const scenarioRunLoadingErrorMessage =
  'An error occurred when loading a Scenario Run. Please try refreshing the page.';
export const scenarioProjectMetricsLoadinFailedErrorMessage =
  'An error occurred when loading a Scenario Project Metrics. Please try refreshing the page.';
export const scenarioNoSolutionFoundErrorTitle = 'NO SOLUTION FOUND';
export const scenarioNoSolutionFoundErrorMessage =
  'We were not able to find a feasible solution using the spatial optimization parameters you entered. Please try entering a different Project Size - Acres, Budget per Project or Number of Projects and try again.';

const checkingScenarioRunStatesInterval = 1000;
const CHECKING_SHAPEFILE_EXPORT_STATUS_INTERVAL = 1000;

export const fetchScenarios = (planningAreaId) => async (dispatch) => {
  let scenarios = [];

  try {
    scenarios = (await listScenarios(await getJWT(), planningAreaId)).data;
  } catch (err) {
    if (err?.response?.status !== 404) {
      return dispatch(showError(scenarioErrorMessage));
    }
  }

  return dispatch({
    type: SET_SCENARIOS,
    payload: {
      uuid: planningAreaId,
      scenarios,
    },
  });
};

export const fetchScenarioObjectives = () => async (dispatch) => {
  let scenarioObjectives = [];

  try {
    scenarioObjectives = (await listScenarioObjectives(await getJWT())).data;
  } catch (err) {
    return dispatch(showError(scenarioObjectivesErrorMessage));
  }

  return dispatch({
    type: SET_SCENARIO_OBJECTIVES,
    payload: {
      scenarioObjectives,
    },
  });
};

export const setNewScenarioObjectiveWeight = (targetUuid, objectiveUuid, weight) => ({
  type: SET_NEW_SCENARIO_OBJECTIVE_WEIGHT,
  payload: {
    targetUuid,
    objectiveUuid,
    weight,
  },
});

export const resetNewScenarioObjectiveWeight = (targetUuid) => ({
  type: RESET_NEW_SCENARIO_OBJECTIVE_WEIGHT,
  payload: {
    targetUuid,
  },
});

export const createScenarioRun = ({
  planningArea,
  scenarioObjectivesWeight,
  objectiveIDs,
  scenarioRunId,
  optimizedValues,
  selectedAreas,
  recreate
}) => async (dispatch, getState) => {
  const { feelingResilient: is_auto = false } = getState().scenario

  if (recreate) {
    dispatch({
      type: SET_RECREATE_SCENARIO_STATUS,
      payload: 'scenario_run_in_progress'
    });
  } else if(is_auto) {
    dispatch({
      type: SET_FEELING_RESILIENT_SCENARIO_STATUS,
      payload: 'scenario_run_in_progress'
    });
  } else {
    dispatch({
      type: SCENARIO_RUN_IN_PROGRESS,
    });
  }

  if (!planningArea) {
    console.error('No planning ID');
    return;
  }

  let requestData = { is_auto };

  const remapObjectiveWeights = (rawData, defaultWeights) =>
    objectiveIDs.map((objectiveId) => ({
      scenario_objective_uuid: objectiveId,
      weight: rawData?.[objectiveId]?.weight || defaultWeights?.[objectiveId]?.weight || 0,
    }))

  // Set Planning Area Weighted Objectives
  requestData['objectives'] = scenarioObjectivesWeight?.[planningArea.uuid] ? remapObjectiveWeights(scenarioObjectivesWeight?.[planningArea.uuid]) : [];

  // Set Emphasis Area Weighted Objectives ensuring all EA for the planning area are included even if they don't have any user specifed weights
  if (planningArea.emphasis_areas) {
    requestData['emphasis_area_objectives'] = []
    planningArea.emphasis_areas.forEach((emphasisArea) => {
      requestData['emphasis_area_objectives'].push({
        emphasis_area_uuid: emphasisArea.uuid,
        objectives: remapObjectiveWeights(scenarioObjectivesWeight?.[emphasisArea.uuid] || {}, scenarioObjectivesWeight?.[planningArea.uuid]),
      })
    });
  }

  if (optimizedValues) {
    requestData['refinement_options'] = optimizedValues;
    requestData['is_refined'] = true;

    // Filter out any areas that have not been selected by User. NOTE: Disable this when is_auto is selected
    if (!is_auto) {
      requestData['objectives'] = selectedAreas?.includes(planningArea.uuid) ? requestData['objectives'] : [];
      requestData['emphasis_area_objectives'] = requestData['emphasis_area_objectives']?.filter((ea) => selectedAreas.includes(ea.emphasis_area_uuid));
    }
  }

  // Add the scenario ID for additional scenario runs
  if (scenarioRunId) requestData['scenario_uuid'] = scenarioRunId;

  let scenarioRun = {};

  try {
    scenarioRun = await generateScenarioRun(await getJWT(), planningArea.uuid, requestData);
    sendAnalyticsEvent('start_scenario_run', {
      optimized: !!requestData['is_refined'],
    });
    dispatch(startCheckingScenarioRunStates());
  } catch (err) {
    if (err?.response?.status !== 404) {
      dispatch(showError(scenarioRunErrorMessage));
      if (recreate) {
        dispatch({
          type: SET_RECREATE_SCENARIO_STATUS,
          payload: 'idle'
        });
      } else if(is_auto) {
        dispatch({
          type: SET_FEELING_RESILIENT_SCENARIO_STATUS,
          payload: 'idle'
        });
      } else {
        dispatch({
          type: SCENARIO_RUN_IN_PROGRESS_STOP,
        });
      }
    }
  }

  if (scenarioRunId) {
    // scenario for this should be updated to have a
    // 'source_scenario_uuid', when it is saved.
    let payload = scenarioRun?.data;
    payload.unsaved_refined = true;
  }

  const currentDatasetVersion = getState().dataset?.currentDataSet?.active_version?.uuid;
  await dispatch(setCurrentDataSetVersionUuid(currentDatasetVersion));
  dispatch(fetchMapLayers());
  return dispatch(setScenarioRun(scenarioRun?.data));
};

export const startCheckingScenarioRunStates = () => async (dispatch, getState) => {
  clearInterval(getState()?.scenario?.checkingScenarioRunStatesTimer);
  let timer = setInterval(
    () => dispatch(fetchCheckingScenarioRunStates()),
    checkingScenarioRunStatesInterval
  );
  return dispatch({
    type: CHECKING_SCENARIO_RUN_STATES_TIMER_START,
    payload: {
      checkingScenarioRunStatesTimer: timer,
    },
  });
};

export const stopCheckingScenarioRunStates = () => async (dispatch, getState) => {
  clearInterval(getState()?.scenario?.checkingScenarioRunStatesTimer);
  return dispatch({
    type: CHECKING_SCENARIO_RUN_STATES_TIMER_STOP,
  });
};

export const fetchCheckingScenarioRunStates = () => async (dispatch, getState) => {
  let data = null;
  try {
    let planningAreaUuid = getState()?.planning?.currentPlanningArea?.uuid;
    let scenarioRunUuid = getState()?.scenario?.currentScenarioRun?.uuid;
    const { recreateScenarioStatus, feelingResilientScenarioStatus} = getState()?.scenario

    data = (await getScenarioRun(await getJWT(), planningAreaUuid, scenarioRunUuid))?.data;
    let lastItem = [...data?.states].pop();
    if (lastItem?.code === 'COMPLETED') {
      sendAnalyticsEvent('scenario_run_completed', { optimized: data?.refined });
      dispatch(stopCheckingScenarioRunStates());

      dispatch({
        type: CURRENT_SCENARIO_OUTDATED,
        payload: false
      });

      if (recreateScenarioStatus === 'scenario_run_in_progress') {
        dispatch({ type: SET_RECREATE_SCENARIO_STATUS, payload: 'scenario_run_finished'})
        // we dont want to fetch project for scenario run when recreating. We will wait until new scenario is created
        return
      }

      if (feelingResilientScenarioStatus === 'scenario_run_in_progress') {
        dispatch({ type: SET_FEELING_RESILIENT_SCENARIO_STATUS, payload: 'scenario_run_finished'})
        // we dont want to fetch project for scenario run when feelingResilient. We will wait until new scenario is created
        return
      }

      try {
        let projects = (
          await getScenarioRunProjects(await getJWT(), planningAreaUuid, scenarioRunUuid)
        )?.data;
        return dispatch({
          type: SET_SCENARIO_RUN_RESULTS,
          payload: {
            projects,
            planningAreaUuid,
            scenarioRunUuid,
          },
        });
      } catch (err) {
        dispatch({
          type: SCENARIO_RUN_IN_PROGRESS_STOP,
        });
        return dispatch(showError(scenarioRunFailedErrorMessage));
      }
    } else if (lastItem?.code === 'FAILED_NOT_FEASIBLE') {
      sendAnalyticsEvent('scenario_run_not_feasible', { optimized: data?.refined });
      dispatch(stopCheckingScenarioRunStates());
      return dispatch(
        showError(scenarioNoSolutionFoundErrorMessage, scenarioNoSolutionFoundErrorTitle)
      );
    } else if (lastItem?.code === 'FAILED') {
      sendAnalyticsEvent('scenario_run_failed', { optimized: data?.refined });
      dispatch(stopCheckingScenarioRunStates());
      return dispatch(showError(scenarioRunFailedErrorMessage));
    }
  } catch (err) {
    return dispatch(stopCheckingScenarioRunStates());
  }
};

export const fetchScenarioDatasetVersion = (planningAreaUuid, scenarioUuid) => async (dispatch) => {
  try {
    const version = await getScenarioDatasetVersion(await getJWT(), planningAreaUuid, scenarioUuid);

    await dispatch(setCurrentDataSetVersionUuid(version.data.uuid));
    await dispatch(fetchMapLayers());

    return dispatch({
      type: CURRENT_SCENARIO_OUTDATED,
      payload: !version?.data?.is_active,
    });
  } catch (err) {
    dispatch({
      type: CURRENT_SCENARIO_OUTDATED,
      payload: false,
    });
    return dispatch(showError(scenarioDatasetVersionErrorMessage));
  }
};

export const fetchLatestScenarioDatasetVersion = (planningAreaUuid, scenarios) => async (
  dispatch
) => {
  try {
    let versionData = null;
    for (let k in scenarios) {
      let version = await getScenarioDatasetVersion(
        await getJWT(),
        planningAreaUuid,
        scenarios[k].uuid
      );

      if (versionData === null) {
        versionData = version.data;
      }

      if (valueOfDateTime(version?.data?.updated_at) > valueOfDateTime(versionData.updated_at)) {
        versionData = version.data;
      }
    }

    await dispatch(setCurrentDataSetVersionUuid(versionData?.uuid));
    await dispatch(fetchMapLayers());

    return dispatch({
      type: CURRENT_SCENARIO_OUTDATED,
      payload: !versionData?.is_active,
    });
  } catch (err) {
    dispatch({
      type: CURRENT_SCENARIO_OUTDATED,
      payload: false,
    });
    return dispatch(showError(scenarioDatasetVersionErrorMessage));
  }
};

export const fetchScenarioProjects = (planningAreaUuid, scenarioRunUuid) => async (dispatch) => {
  dispatch({
    type: SCENARIO_PROJECTS_LOADING_IN_PROGRESS,
  });

  try {
    let projects = (await getScenarioRunProjects(await getJWT(), planningAreaUuid, scenarioRunUuid))
      ?.data;

    /* START DATA DEBUG STATEMENTS */
    /* Inserting console.log statements to help Data Team with debugging */
    /* This should probably be removed once data validation is completed. */
    /* -Kevin */
    if (!isProduction() && !isLocal()) {
      console.log(`Planning Area UUID: ${planningAreaUuid}`);
      projects.forEach((p) => {
        console.log(`Project ${p.in_patch_id} Unit Ids:`);
        p.unit_ids.forEach((id) => console.log(id));
      });
    }
    /* END DATA DEBUG STATEMENTS */

    return dispatch({
      type: SET_SCENARIO_RUN_RESULTS,
      payload: {
        projects,
        planningAreaUuid,
        scenarioRunUuid,
      },
    });
  } catch (err) {
    dispatch({
      type: SCENARIO_PROJECTS_LOADING_IN_PROGRESS_STOP,
    });
    return dispatch(showError(scenarioProjectsLoadinFailedErrorMessage));
  }
};

export const createScenario = ({
  name,
  planningAreaUuid,
  scenarioRunUuid,
  replaceScenarioPath,
  description,
  recreatedFrom
}) => async (dispatch, getState) => {
  const { recreateScenarioStatus, feelingResilientScenarioStatus} = getState()?.scenario
  dispatch({ type: CREATE_SCENARIO_IN_PROGRESS });
  try {
    const scenario = await createScenarioAPI(
      await getJWT(),
      {
        name,
        planningAreaUuid,
        scenarioRunUuid,
        description,
        recreatedFrom
      }
    );

    if (recreateScenarioStatus === 'scenario_run_finished') {
      dispatch({ type: SET_RECREATE_SCENARIO_STATUS, payload: 'idle' })
    } else if(feelingResilientScenarioStatus === 'scenario_run_finished') {
      dispatch({ type: SET_FEELING_RESILIENT_SCENARIO_STATUS, payload: 'idle' })
    }
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
    sendAnalyticsEvent('save_scenario', { optimized: false });
    await dispatch(resetNewScenarioObjectiveWeight(planningAreaUuid));
    await dispatch(fetchScenarios(planningAreaUuid));
    if (scenario?.data?.uuid) {
      await replaceScenarioPath(planningAreaUuid, scenario?.data?.uuid);
    }
  } catch (e) {
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
    dispatch(showError(e?.response?.data?.message));
  }
};

export const setCurrentScenario = (scenario) => async (dispatch, getState) => {
  await dispatch({
    type: SET_CURRENT_SCENARIO,
    payload: scenario,
  });

  if (scenario) {
    sendAnalyticsEvent('open_scenario', { optimized: scenario.scenario_run.refined });
    const { currentPlanningArea } = getState().planning;
    await dispatch(fetchScenarioRun(currentPlanningArea.uuid, scenario.scenario_run.uuid));
    await dispatch(fetchScenarioDatasetVersion(currentPlanningArea.uuid, scenario.uuid));
    return dispatch(fetchScenarioProjects(currentPlanningArea.uuid, scenario.scenario_run.uuid));
  }
};

export const createOptimizedScenario = (
  name,
  planningAreaUuid,
  scenarioRunUuid,
  sourceScenarioUuid,
  replaceScenarioPath,
  description
) => async (dispatch) => {
  dispatch({ type: CREATE_SCENARIO_IN_PROGRESS });
  try {
    const scenario = await createOptimizedScenarioAPI(
      await getJWT(),
      name,
      planningAreaUuid,
      scenarioRunUuid,
      sourceScenarioUuid,
      description
    );
    sendAnalyticsEvent('save_scenario', { optimized: true });
    await dispatch(fetchScenarios(planningAreaUuid));
    // TODO update \<unsaved_refined\>
    if (scenario?.data?.uuid) {
      await replaceScenarioPath(planningAreaUuid, scenario?.data?.uuid);
    }
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
  } catch (e) {
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
    return dispatch(showError('An error occurred when calling the API.'));
  }
};

export const fetchScenarioRun = (planningAreaUuid, scenarioRunUuid) => async (dispatch) => {
  dispatch({ type: SCENARIO_RUN_LOADING_IN_PROGRESS });
  try {
    let data = (await getScenarioRun(await getJWT(), planningAreaUuid, scenarioRunUuid))?.data;
    return await dispatch(setScenarioRun(data));
  } catch (err) {
    dispatch({ type: SCENARIO_RUN_LOADING_IN_PROGRESS_STOP });
    return dispatch(showError(scenarioRunLoadingErrorMessage));
  }
};

export const setScenarioRun = (scenarioRun) => (dispatch) => {
  return dispatch({
    type: SET_SCENARIO_RUN,
    payload: scenarioRun,
  });
};

export const resetCurrentScenarioRun = () => async (dispatch) => {
  return dispatch({
    type: RESET_SCENARIO_RUN,
  });
};

export const clearCurrentScenarioRun = () => async (dispatch) => {
  return dispatch({
    type: CLEAR_SCENARIO_RUN,
  });
};

export const deleteScenario = (planningAreaId, scenarioUuid) => async (dispatch) => {
  await dispatch({ type: DELETE_SCENARIO_IN_PROGRESS });
  try {
    await deleteScenarioAPI(await getJWT(), planningAreaId, scenarioUuid);
    sendAnalyticsEvent('delete_scenario');
    dispatch(fetchScenarios(planningAreaId));
    return dispatch(fetchComparisons(planningAreaId));
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const fetchScenarioProjectMetrics = (
  planningAreaUuid,
  scenarioRunUuid,
  projectUuid,
  name
) => async (dispatch) => {
  dispatch({
    type: SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS,
  });
  try {
    let data = (
      await getScenarioProjectMetrics(
        await getJWT(),
        planningAreaUuid,
        scenarioRunUuid,
        projectUuid
      )
    )?.data;

    data['name'] = name;
    await dispatch({
      type: SET_SCENARIO_PROJECT_METRICS,
      payload: {
        data,
        planningAreaUuid,
        scenarioRunUuid,
      },
    });

    return await dispatch({
      type: SET_CURRENT_SCENARIO_PROJECT_METRICS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SCENARIO_PROJECT_METRICS_LOADING_IN_PROGRESS_STOP,
    });
    return dispatch(showError(scenarioProjectMetricsLoadinFailedErrorMessage));
  }
};

export const showProjectMetricsPopUp = (
  planningAreaUuid,
  scenarioRunUuid,
  projectUuid,
  name
) => async (dispatch, getState) => {
  sendAnalyticsEvent('show_scenario_project_popup');
  dispatch(fetchScenarioProjectMetrics(planningAreaUuid, scenarioRunUuid, projectUuid, name));
  if (getState()?.planning?.currentPlanningAreaObjectives === null) {
    const scenarioUuid = getState()?.scenario?.currentScenario?.uuid;
    return dispatch(fetchPlanningAreaObjectives(scenarioUuid, planningAreaUuid));
  }
};

export const hideProjectMetricsPopUp = () => async (dispatch) => {
  sendAnalyticsEvent('hide_scenario_project_popup');
  return await dispatch({
    type: SET_CURRENT_SCENARIO_PROJECT_METRICS,
    payload: null,
  });
};

export const showPolygonMetricsPopUp = (unitId, fillColor) => async (dispatch, getState) => {
  const { currentDataSet = null, currentDataSetVersionUuid } = getState().dataset;
  try {
    let data = (
      await getPolygonMetrics(
        await getJWT(),
        currentDataSet?.uuid,
        currentDataSetVersionUuid,
        unitId
      )
    )?.data;
    sendAnalyticsEvent('show_scenario_polygon_popup');

    const {
      currentPlanningArea = null,
      currentPlanningAreaObjectives = null,
    } = getState().planning;

    const scenarioUuid = getState()?.scenario?.currentScenario?.uuid;

    if (currentPlanningAreaObjectives === null && scenarioUuid) {
      dispatch(fetchPlanningAreaObjectives(scenarioUuid, currentPlanningArea?.uuid));
    }

    data['fillColor'] = fillColor;
    return await dispatch({
      type: SET_CURRENT_POLYGON_METRICS,
      payload: data,
    });
  } catch (e) {
    return dispatch(showError(showPolygonMetricsPopUpErrorMessage));
  }
};

export const hidePolygonMetricsPopUp = () => async (dispatch) => {
  sendAnalyticsEvent('hide_scenario_polygon_popup');
  return await dispatch({
    type: SET_CURRENT_POLYGON_METRICS,
    payload: null,
  });
};

export const toggleSizeWarning = (value) => async (dispatch) => {
  return await dispatch({
    type: TOGGLE_SIZE_WARNING,
    payload: value,
  });
};

export const toggleProject = (uuid) => async (dispatch, getState) => {
  let deselectedProjects = [].concat(getState()?.scenario?.deselectedProjects);
  let index = deselectedProjects.indexOf(uuid);
  if (index !== -1) {
    deselectedProjects.splice(index, 1);
  } else {
    deselectedProjects.push(uuid);
  }
  return await dispatch({
    type: SET_DESELECTED_PROJECTS,
    payload: deselectedProjects,
  });
};

export const setRenameScenario = (scenario = null) => ({
  type: SET_RENAME_SCENARIO,
  payload: scenario,
});
export const updateScenario = (name, description) => async (dispatch, getState) => {
  let currentPlanningAreaId = getState().planning.currentPlanningArea.uuid;
  try {
    await updateScenarioAPI(
      await getJWT(),
      currentPlanningAreaId,
      getState().scenario.renameScenario.uuid,
      name,
      description
    );
    dispatch(setRenameScenario(null));
    return dispatch(fetchScenarios(currentPlanningAreaId));
  } catch (e) {
    return dispatch(showError(e?.response?.data?.message));
  }
};
export const startShapeFileExport = (planningAreaId, scenarioRunId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHAPE_FILE_EXPORT_IN_PROGRESS,
      payload: true,
    });
    let shapeFileStartExportResponse = await startShapeFileExportAPI(
      await getJWT(),
      planningAreaId,
      scenarioRunId
    );
    return dispatch(
      startCheckingShapeFileExportStatus(shapeFileStartExportResponse?.data?.shapefile_export_uuid)
    );
  } catch (err) {
    dispatch({
      type: SET_SHAPE_FILE_EXPORT_IN_PROGRESS,
      payload: false,
    });
    return dispatch(showError());
  }
};
export const fetchCheckingShapeFileExportStatus = () => async (dispatch, getState) => {
  let planningAreaUuid = getState()?.planning?.currentPlanningArea?.uuid;
  let { currentScenario, currentScenarioRun, shapeFileExportUuid } = getState()?.scenario;

  let scenarioName = currentScenario?.name?.toLowerCase()
    .replace(/\s+/g, '_');

  let shapeFileExportStatusResponse = await getShapeFileExportStatusAPI(await getJWT(),
    planningAreaUuid,
    currentScenarioRun?.uuid,
    shapeFileExportUuid
  );

  shapeFileExportStatusResponse?.data?.forEach((item) => {
    if (item.state === 'COMPLETED') {
      fetch(item['download_url'], {
        responseType: 'blob',
      })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, `${scenarioName}_shapefile.zip`);
      });
      return dispatch(stopCheckingShapeFileExportStatus());
    } else if (item.state === 'FAILED') {
      dispatch(stopCheckingShapeFileExportStatus());
      return dispatch(showError());
    }
  });
};

export const startCheckingShapeFileExportStatus = (shapeFileExportUuid) => async (
  dispatch,
  getState
) => {
  clearInterval(getState()?.scenario?.shapeFileExportStatusTimer);
  let timer = setInterval(
    () => dispatch(fetchCheckingShapeFileExportStatus()),
    CHECKING_SHAPEFILE_EXPORT_STATUS_INTERVAL
  );
  return dispatch({
    type: CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_START,
    payload: {
      shapeFileExportStatusTimer: timer,
      shapeFileExportUuid: shapeFileExportUuid,
    },
  });
};
export const stopCheckingShapeFileExportStatus = () => async (dispatch, getState) => {
  clearInterval(getState()?.scenario?.shapeFileExportStatusTimer);
  return dispatch({
    type: CHECKING_SHAPE_FILE_EXPORT_STATUS_TIMER_STOP,
  });
};

export const createSpatialScenario = (
    planningAreaUuid, 
    scenariCoomparisonUuid, 
    projects, 
    openSpatialScenarioPage
  ) => async (dispatch) => {
  dispatch({ type: CREATE_SCENARIO_IN_PROGRESS });
  try {
    const response = await createSpatialScenarioAPI(
      await getJWT(),
      planningAreaUuid,
      scenariCoomparisonUuid, 
      projects,
    );
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
    openSpatialScenarioPage(response?.data?.uuid);
  } catch (e) {
    dispatch({ type: CREATE_SCENARIO_IN_PROGRESS_STOP });
    return dispatch(showError(e?.response?.data?.message));
  }
};

export const setSelectedOptimizeAreas = (selectedOptimizeAreas) => async (dispatch) => {
  return await dispatch({
    type: SET_SELECTED_OPTIMIZE_AREAS,
    payload: selectedOptimizeAreas,
  });
}

export const setFeelingResilient = (value) => ({
  type: SET_FEELING_RESILIENT,
  payload: value
})

