const ENVIRONMENT_LOCAL = 'LOCAL';
const ENVIRONMENT_DEVELOPMENT = 'DEVELOPMENT';
const ENVIRONMENT_STAGING = 'STAGING';
const ENVIRONMENT_PRODUCTION = 'PRODUCTION';

export const SIGN_UP_PASSCODE = '112020';

const parseEnvironment = () => {
  if (window.location.hostname.includes('dev.')) {
    return ENVIRONMENT_DEVELOPMENT;
  } else if (window.location.hostname.includes('staging.')) {
    return ENVIRONMENT_STAGING;
  } else if (window.location.hostname === 'landtender.net' ||
             window.location.hostname.includes('app.')) {
    return ENVIRONMENT_PRODUCTION;
  }

  return ENVIRONMENT_LOCAL;
};

export const ENVIRONMENT = parseEnvironment();
export const isLocal = () => ENVIRONMENT === ENVIRONMENT_LOCAL;
export const isDevelopment = () => ENVIRONMENT === ENVIRONMENT_DEVELOPMENT;
export const isStaging = () => ENVIRONMENT === ENVIRONMENT_STAGING;
export const isProduction = () => ENVIRONMENT === ENVIRONMENT_PRODUCTION;

// let local and dev use the same credentials for the dev environment.
let cognito = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:1e019322-0f0f-4ec9-98f4-a49e94a82197',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_xxo3rmANL',
  aws_user_pools_web_client_id: '19dgl29mu1n3icf0uii3ld05ag',
  aws_user_files_s3_bucket_region: 'us-west-2',
  aws_user_files_s3_bucket: 'vp-dev-profile',
  oauth: {},
};
let mixpanel = {
  enabled: false,
};
let ga = {
  enabled: false,
};
let newrelic = {
  enabled: false,
}
let api = {
  defaultTimeout: 180000,
  version: 'v1',
  baseUrl: process.env.REACT_APP_BASE_URL || 'https://api.dev.landtender.net',
  application_key: process.env.REACT_APP_APPLICATION_KEY || '1934fe76d44e48fe91a3b9adc0e95efa'
};
let mapbox = {
  accessToken:
    'pk.eyJ1IjoidmlicmFudHBsYW5ldCIsImEiOiJja2p2cHB3MzkwNzVvMnpxb2Z3dXNhOWl3In0.-U7T_JxQv-a80jT0H98j_Q',
  center: [-121, 39.58],
  maxBounds: [
    [-121.462093102846, 39.3702401753189],
    [-120.430224623531, 39.7209318799852],
  ],
  minZoom: 10,
  maxZoom: 14,
  accountUrl: 'https://api.mapbox.com/styles/v1/vibrantplanet/',
  basemapStyle: 'ckqvcetmw0ceh17nbcaol29xe',
};
mapbox.basemapUrl = `mapbox://styles/vibrantplanet/${mapbox.basemapStyle}`;

let contentful = {
  space: '9ppocv8v0qhg',
  accessToken: 'g26cuUIOoz0lj1Il2qCPrXENNa-_67UnCJrQ_OCosKw',
  userOrientationTypeId: 'userOrientation',
  howToVideosTypeId: 'howToVideo',
};

if (isDevelopment()) {
  newrelic = {
    enabled: true,
    loader_config: {accountID:"3184173",trustKey:"3184173",agentID:"919100424",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919099370"},
    info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919099370",sa:1},
  }
}

if (isStaging()) {
  newrelic = {
    enabled: true,
    loader_config: {accountID:"3184173",trustKey:"3184173",agentID:"919100424",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919099975"},
    info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919099975",sa:1},
  }
  cognito = {
    ...cognito,
    aws_cognito_identity_pool_id: 'us-west-2:05bf5b3a-55ea-45bc-83de-a63bfda6e744',
    aws_user_pools_id: 'us-west-2_dN1xkmQzv',
    aws_user_pools_web_client_id: '7rqj33sumau0imq1n14m97lurc',
    aws_user_files_s3_bucket: 'vp-stage-profile',
  };
  api = {
    ...api,
    baseUrl: 'https://api.staging.landtender.net',
    application_key: 'dbf6d5ceb0604f0d8a39411fc8c54cc6',
  };
}
if (isProduction()) {
  ga = {
    enabled: true,
    token: 'G-N9M66VG6M7',
  };
  mixpanel = {
    enabled: true,
    token: '68e563635e91fb974b4bf14f88423966',
  };
  newrelic = {
    enabled: true,
    loader_config: {accountID:"3184173",trustKey:"3184173",agentID:"919100424",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919100424"},
    info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-747520a1192c85b6a87",applicationID:"919100424",sa:1},
  }
  cognito = {
    ...cognito,
    aws_cognito_identity_pool_id: 'us-west-2:bcaf5067-33ac-4b08-afb0-96dd2558daaa',
    aws_user_pools_id: 'us-west-2_9s0tREcxH',
    aws_user_pools_web_client_id: 't7vl171fdl09iaghhbsdkl1r9',
    aws_user_files_s3_bucket: 'vp-prod-profile',
  };
  api = {
    ...api,
    baseUrl: 'https://api.landtender.net',
    application_key: '9ccede1557e841548069c0fe0ca03357',
  };
}

const CONFIGURATION = {
  ga,
  mixpanel,
  newrelic,
  cognito,
  api,
  mapbox,
  contentful,
};

export default CONFIGURATION;
