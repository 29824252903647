import { login, signup, forgotPassword, getJWT, updateAttributes, forgotPasswordSubmit, changePassword, signout, storagePut, storageList, signedURL } from 'services/auth';
import { fetchPlanningAreas, fetchMapLayers } from './planning';
import { fetchScenarioObjectives } from './scenario';
import { checkPendingUserAgreement, checkForPendingUserOrientation } from './application';
import { sendAnalyticsEvent, updateAnalyticsUser, clearAnalyticsUser } from 'util/analytics';

export const IN_PROGRESS = 'IN_PROGRESS';
export const FAILED = 'FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const RESET_MESSAGE_SENT = 'RESET_MESSAGE_SENT';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const SET_PASSWORD_CHANGED_POPUP_VISIBILITY = 'SET_PASSWORD_CHANGED_POPUP_VISIBILITY';

const parseAnalyticsAttr = (attr) => ({
  organization: attr['custom:organization'],
  email: attr?.email,
  first_name: attr?.name,
  last_name: attr?.family_name,
});

export const checkSession = () => async (dispatch) => {
  const clearSessionAndNotifyUser = async (err) => {
    await dispatch({ type: CLEAR_SESSION });
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  };
  try {
    await getJWT();
  } catch (err) {
    return dispatch({ type: CLEAR_SESSION });
  }
  try {
    await dispatch(checkPendingUserAgreement());
    await dispatch(checkForPendingUserOrientation());
    await dispatch(loadImgURL(dispatch));
    // verify that one of the client APIs is accessible with these cognito
    // credentials:
    await dispatch(fetchPlanningAreas());

    // If we're here, we know we can access the API, no need to block at this point.
    dispatch(fetchMapLayers()).catch(clearSessionAndNotifyUser);
    dispatch(fetchScenarioObjectives()).catch(clearSessionAndNotifyUser);
  } catch (err) {
    await clearSessionAndNotifyUser(err);
  }
};

export const loginUser = (email, password) => async (dispatch, getState) => {
  await dispatch({ type: IN_PROGRESS });
  try {
    const user = await login(email, password);
    const attributes = user?.attributes;

    await checkSession()(dispatch);

    // if we failed to load data, lets not pretend like we're online!
    if (getState()?.user?.authError === undefined) {
      throw new Error('Unable to load user data.');
    }
    if (getState()?.user?.authError) {
      throw new Error(getState().user.authError);
    }

    
    updateAnalyticsUser(attributes?.email,  parseAnalyticsAttr(attributes));
    sendAnalyticsEvent('user_logged_in');

    dispatch({
      type: UPDATE_USER,
      payload: attributes
    });
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const signoutUser = () => async (dispatch) => {
  await dispatch({ type: IN_PROGRESS });
  try {
    await signout();
    clearAnalyticsUser();
    sendAnalyticsEvent('user_logged_out');
    dispatch({ type: CLEAR_SESSION });
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const updatePassword = (oldPassword, newPassword) => async (dispatch) => {
  await dispatch({ type: IN_PROGRESS });
  try {
    await changePassword(oldPassword, newPassword);
    dispatch({
      type: SET_PASSWORD_CHANGED_POPUP_VISIBILITY,
      payload: true,
    });
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const signupUser = (values) => async (dispatch, getState) => {
  // AWS Cognito interfaces with SignUpParams
  let signupParams = {
    attributes: {
      name: values.firstname,
      family_name: values.lastname,
      'custom:organization': values.organization ? values.organization : '',
    },
    username: values.email,
    password: values.password,
  };
  await dispatch({ type: IN_PROGRESS });
  try {
    const response = await signup(signupParams);
    let user = response?.user;

    if (!user) {
      throw new Error('No user returned');
    }

    // AWS Amplify 'SignUp' doesn't automatically 'SignIn'
    await loginUser(user.username, values.password)(dispatch, getState);

    updateAnalyticsUser(signupParams.attributes?.email,  parseAnalyticsAttr(signupParams.attributes));
    sendAnalyticsEvent('user_created');
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const resetUser = (values) => async (dispatch) => {
  await dispatch({ type: IN_PROGRESS });

  try {
    let response;
    if (values.code) {
      let email = values.confirmemail,
          code = values.code,
          password = values.newpassword;

      response = await forgotPasswordSubmit(email, code, password);
      if (response !== undefined && !response) {
        throw new Error('Failed to reset password.');
      }
      sendAnalyticsEvent('user_password_reset');
    } else {
      response = await forgotPassword(values.email);
      if (!response) {
        throw new Error('Failed to send reset password message.');
      }
      sendAnalyticsEvent('user_request_password_reset_email');
    }
    // In order to keep the same route, watch for the dispatch 'RESET_MESSAGE_SENT'
    dispatch({
      type: RESET_MESSAGE_SENT,
    });
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const update = (attributes) => async (dispatch) => {
  await dispatch({ type: IN_PROGRESS });
  try {
    await updateAttributes(attributes);
    updateAnalyticsUser(attributes?.email,  parseAnalyticsAttr(attributes));
    sendAnalyticsEvent('user_update_profile');
    dispatch({
      type: UPDATE_USER,
      payload: attributes,
    });
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }
};

export const updateAvatar = (file) => async (dispatch) => {
  await dispatch({ type: IN_PROGRESS });
  try {
    sendAnalyticsEvent('user_update_avatar');
    await storagePut(file);
    await loadImgURL()(dispatch);
  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
  }

};

export const loadImgURL = () => async (dispatch) => {
  try {
    const avatarList = await storageList()
    let imgURL = null;
    if (avatarList.length > 0) {
      imgURL = await signedURL();
    }

    dispatch({
      type: UPDATE_AVATAR,
      payload: {
        imgURL
      }
    });

  } catch (err) {
    dispatch({
      type: FAILED,
      payload: err.message,
    });
    dispatch({
      type: UPDATE_AVATAR,
      payload: {
        imgURL: `/static/media/LandTender_logo_white.1fc6ef5f.png`
      }
    });

  }
}
export const resetErrorMessage = () => async (dispatch) => {
  dispatch({
    type: RESET_ERROR_MESSAGE,
  });
}
export const closePasswordChangedPopup = () => async (dispatch) => {
  dispatch({
    type: SET_PASSWORD_CHANGED_POPUP_VISIBILITY,
    payload: false,
  });
}