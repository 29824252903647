import configuration from 'config';
import mixpanel from 'mixpanel-browser';

export const initAnalytics = () => {
    if (configuration.mixpanel.enabled) {
        mixpanel.init(configuration.mixpanel.token, {batch_requests: true});
    }
    if (configuration.ga.enabled) {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag(){window.dataLayer.push(arguments);}
        window.gtag('js', new Date());
        window.gtag('config', configuration.ga.token);
    }
}

export const sendAnalyticsEvent = (event, props = {}) => {
    const user = JSON.parse(localStorage.getItem('redux_localstorage_simple_user.attributes'))

    if (configuration.mixpanel.enabled) {
        mixpanel.track(event, props);
    }
    if (configuration.ga.enabled) {
        window.gtag('event', event, user? { email: user?.email, ...props } : props);
    }
};

export const updateAnalyticsUser = (userId, attr = {}) => {
    if (configuration.mixpanel.enabled) {
        mixpanel.identify(userId);
        mixpanel.people.set(attr);
    }
    if (configuration.ga.enabled) {
        window.gtag('config', configuration.ga.token, {
            'user_id': userId
        });
    }
}

export const clearAnalyticsUser = () => {
    if (configuration.mixpanel.enabled) {
        mixpanel.identify();
    }
    if (configuration.ga.enabled) {
        window.gtag('config', configuration.ga.token);
    }
}
