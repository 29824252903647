import {
  SET_DATA_SETS,
  SET_CURRENT_DATA_SET,
  SET_CURRENT_DATA_SET_VERSION,
  SET_GEOGRAPHY_MODAL_VISIBILITY,
} from 'actions/dataset';

import { CLEAR_SESSION } from 'actions/user';

export const DEFAULT_STATE = {
  // List of all datasets returned by the API
  dataSets: [],
  // The current dataset that the UX is working with.
  currentDataSet: null,
  // The dataset version that the UX is working with (typically the 'active version')
  currentDataSetVersionUuid: null,
  isGeographyModalVisible: false,
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SET_DATA_SETS:
      return {
        ...state,
        dataSets: action.payload
      }; 
    case SET_CURRENT_DATA_SET_VERSION:
      return {
        ...state,
        currentDataSetVersionUuid: action.payload
      }; 
    case SET_CURRENT_DATA_SET:
      return {
        ...state,
        currentDataSet: action.payload,
        currentDataSetVersionUuid: action.payload?.active_version?.uuid
      }; 
    case SET_GEOGRAPHY_MODAL_VISIBILITY:
      return {
        ...state,
        isGeographyModalVisible: action.payload
      };
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      };                    
    default:
      return state;
  }
};

export default reducer;
