import {
  ACKNOWLEDGE_ERROR,
  SHOW_ERROR,
  SHOW_CONFIRM,
  CANCEL_CONFIRM,
  SET_PENDING_USER_AGREEMENT,
  SET_ACCEPTED_USER_AGREEMENT,
  SET_USER_ORIENTATION_ITEMS,
  SET_HOW_TO_VIDEO_ITEMS,
} from 'actions/application';

const DEFAULT_STATE = {
  // application error
  error: null,
  
  // application confirm
  confirm: null,
  /* pending user agreement
    {
      "uuid": <String>,
      "download_url": <String>,
      "text": <String>,
    }
  */
  pendingUserAgreement: null,

  /* accepted user agreement 
      {
      "uuid": <String>,
      "download_url": <String>,
      "text": <String>,
    }
  */
  acceptedUserAgreement: null,

  /* user orientation content items form contentful.com 
      <Array>
  */
  userOrientationItems: null,
    /* how to videos content items form contentful.com 
      <Array>
  */
  userHowToVideoItems: null,
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case ACKNOWLEDGE_ERROR:
      return {
        ...state,
        error: null
      };
    case SHOW_ERROR:
      return {
        ...state,
        error: action.payload   
      };
    case SHOW_CONFIRM:
      return {
        ...state,
        confirm: action.payload     
      };
    case CANCEL_CONFIRM:
      return {
        ...state,
        confirm: null
      };
    case SET_PENDING_USER_AGREEMENT:
      return {
        ...state,
        pendingUserAgreement: action.payload,
      };   
    case SET_ACCEPTED_USER_AGREEMENT:
      return {
        ...state,
        acceptedUserAgreement: action.payload,
      };
    case SET_USER_ORIENTATION_ITEMS:
      return {
        ...state,
        userOrientationItems: action.payload,
      };    
    case SET_HOW_TO_VIDEO_ITEMS:
      return {
        ...state,
        userHowToVideoItems: action.payload,
      };  
    default:
      return state;
  }
};

export default reducer;