import {
  SET_PLANNING_AREAS,
  SET_ACTIVE_AREA,
  SET_MAP_LAYERS,
  STORE_MAP_LAYERS,
  SET_MAP_GROUP_VISIBILITY,
  SET_MAP_LAYER_VISIBILITY,
  RESET_MAP_LAYER_VISIBILITY,
  SET_IN_PROGRESS,
  SET_CURRENT_PLANNING_AREA,
  SET_SHARE_PLANNING_AREA,
  SET_PLANNING_AREA_SHARES,
  SET_RENAME_PLANNING_AREA,
  SET_CURRENT_PLANNING_AREA_OBJECTIVES,
  ADD_CURRENT_EMPHASIS_AREA,
  UPDATE_CURRENT_EMPHASIS_AREA,
  DELETE_CURRENT_EMPHASIS_AREA,
  SET_DRAW_CONTROL_MODE,
  TOGGLE_SHAPEFILE_UPLOAD_MODAL,
  SET_DRAW_TYPE,
  mapLayersIndex,
} from 'actions/planning';

import { CLEAR_SESSION } from 'actions/user';

export const DEFAULT_STATE = {
  // Whether the current planning area is in the process of being saved.
  planningInProgress: false,
  // The current Planning Area that has been selected.
  currentPlanningArea: null,
  // Last API call to a user's Planning Areas.
  planningAreas: [],
  // current mapLayers related to currentPlanningArea
  mapLayers: { unitIdUrl: '', categories: [], unit_ids: [] },
  // Last API call to map-layers for the entire dataset. Each entry contains
  // geometry, and mapLayer keys.
  storedMapLayers: [],
  // Layers that are visible on the map.
  mapLayerVisibility: {},
  // mapLayers groups that are expanded in the UI.
  mapGroupVisibility: {},
  // Current PlanningArea that is being shared.
  sharePlanningArea: null,
  // Shares associated with the current 'sharePlanningArea'
  planningAreaSharesList: [],
  renamePlanningArea: null,
  currentPlanningAreaObjectives: null,
  drawControlMode: 'simple_select',
  showShapeFileUploadModal: false,
  drawType: null,
  currentActiveArea: null
};

const reducer = (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SET_CURRENT_PLANNING_AREA:
      return {
        ...state,
        currentPlanningAreaObjectives: null,
        currentPlanningArea: action.payload ? { ...action.payload } : null,
      };
    case SET_PLANNING_AREAS:
      return {
        ...state,
        planningAreas: action.payload,
        planningInProgress: false,
      };
    case SET_IN_PROGRESS:
      return {
        ...state,
        planningInProgress: action.payload ?? true,
      };
    case SET_MAP_GROUP_VISIBILITY:
      return {
        ...state,
        mapGroupVisibility: {
          ...state.mapGroupVisibility,
          [action.payload.categoryUuid]: {
            visibleGroup: action.payload.mapGroupUuid,
          }
        },
      };
    case SET_MAP_LAYER_VISIBILITY:
      return {
        ...state,
        mapLayerVisibility: {
          ...state.mapLayerVisibility,
          [action.payload?.datasetUuid]: {
            ...state.mapLayerVisibility[action.payload?.datasetUuid],
            [action.payload.layerUuid]: {
              opacity: action.payload.opacity,
              visible: action.payload.visible,
            }
          }
        },
      };
    case RESET_MAP_LAYER_VISIBILITY:
      return {
        ...state,
        mapLayerVisibility: action.payload?.datasetUuid ? {
          ...state.mapLayerVisibility,
          [action.payload?.datasetUuid]: {}
        } : {},
      };

    case SET_MAP_LAYERS:
      return {
        ...state,
        mapLayers: action.payload,
      };
    case STORE_MAP_LAYERS:
      const idx = mapLayersIndex(
        state.storedMapLayers, 
        action.payload.geometry, 
        action.payload.planningAreaUuid,
        action.payload.datasetVersionUuid,
      );
      return {
        ...state,
        storedMapLayers: [
          ...( idx >=0 ? state.storedMapLayers.slice(0, idx) : []),
          { ...action.payload },
          ...state.storedMapLayers.slice(idx + 1),
        ]
      };
    case SET_SHARE_PLANNING_AREA:
      return {
        ...state,
        sharePlanningArea: action.payload,
        planningAreaSharesList: [],
      };
    case SET_PLANNING_AREA_SHARES:
      return {
        ...state,
        planningAreaSharesList: action.payload,
      };
    case SET_RENAME_PLANNING_AREA:
      return {
        ...state,
        renamePlanningArea: action.payload     
      };
    case SET_CURRENT_PLANNING_AREA_OBJECTIVES:
      return {
        ...state,
        currentPlanningAreaObjectives: action.payload,
      };
    case CLEAR_SESSION:
      return {
        ...DEFAULT_STATE,
      };  
    case SET_ACTIVE_AREA:
      return {
        ...state,
        currentActiveArea: action.payload,
      }
    case ADD_CURRENT_EMPHASIS_AREA:
      const currentEmphasisAreas = state.currentPlanningArea?.emphasis_areas || [];
      return {
        ...state,
        currentPlanningArea: {
          ...state.currentPlanningArea,
          emphasis_areas: [...currentEmphasisAreas, action.payload],
        }
      };   
    case UPDATE_CURRENT_EMPHASIS_AREA:
      return {
        ...state,
        currentPlanningArea: {
          ...state.currentPlanningArea,
          emphasis_areas: action.payload,
        }
      }; 
    case DELETE_CURRENT_EMPHASIS_AREA:
      return {
        ...state,
        currentPlanningArea: {
          ...state.currentPlanningArea,
          emphasis_areas: state.currentPlanningArea?.emphasis_areas?.filter(ea => ea.uuid !== action.payload && ea.id !== action.payload),
        }
      }

    case SET_DRAW_CONTROL_MODE:
      return {
        ...state,
        drawControlMode: action.payload
      }

    case TOGGLE_SHAPEFILE_UPLOAD_MODAL:
      return {
        ...state,
        showShapeFileUploadModal: !state.showShapeFileUploadModal
      }

    case SET_DRAW_TYPE:
      return {
        ...state,
        drawType: action.payload
      }

    default:
      return state;
  }
};

export default reducer;
