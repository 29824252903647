import { 
  fetchPendingUserAgreement, 
  fetchAcceptedUserAgreement, 
  acceptUserAgreement as acceptUserAgreementApi 
} from 'services/api';
import configuration from 'config';
import { getContentfulEntries } from 'services/contentful';
import { getJWT } from 'services/auth';
import { checkSession } from './user';

export const ACKNOWLEDGE_ERROR = 'ACKNOWLEDGE_ERROR';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const CANCEL_CONFIRM = 'CANCEL_CONFIRM';
export const SET_PENDING_USER_AGREEMENT = 'SET_PENDING_USER_AGREEMENT';
export const SET_ACCEPTED_USER_AGREEMENT = 'SET_ACCEPTED_USER_AGREEMENT';
export const SET_USER_ORIENTATION_ITEMS = 'SET_USER_ORIENTATION_ITEMS';
export const SET_HOW_TO_VIDEO_ITEMS = 'SET_HOW_TO_VIDEO_ITEMS';

const HIDE_USER_ORIENTATION = "VP_HIDE_USER_ORIENTATION";

export const acknowledgeError = () => ({
    type: ACKNOWLEDGE_ERROR
});

export const defaultErrorMessage = "We’re sorry but an error occurred. Please try again.";
export const defaultErrorTitle = "WHOOPS!";
export const showError = (errorMessage=defaultErrorMessage, errorTitle= defaultErrorTitle) => ({
  type: SHOW_ERROR,
  payload: {
    message: errorMessage,
    title: errorTitle,
  }
});

const defaultConfirmTitle = "Delete Planning Area";
const defaultConfirmText = "Delete Planning Area";
const defaultContent = <>
  You are about to delete this Planning Area. By Deleting this Planning Area, you will also delete all related Scenarios and Comparisons.
  <br/><br/> You cannot undo this request.
</>

export const showConfirm = ({
  onConfirm, 
  title = defaultConfirmTitle, 
  confirmText = defaultConfirmText, 
  subTitle, 
  content = defaultContent,
}) => ({
  type: SHOW_CONFIRM,
  payload: {
    onConfirm: onConfirm,
    title: title,
    confirmText: confirmText,
    subTitle: subTitle,
    content: content
  } 
});
export const cancelConfirm = () => ({
  type: CANCEL_CONFIRM
});
export const onConfirm = () => async (dispatch, getState) => {
  getState()?.application?.confirm?.onConfirm();
  dispatch({ type: CANCEL_CONFIRM });
};

export const checkPendingUserAgreement = () => async (dispatch) => {
  let pendingUserAgreement;
  try {
    pendingUserAgreement = await fetchPendingUserAgreement(await getJWT());
  } catch (err) {
    return dispatch(showError());
  }

  return dispatch({
    type: SET_PENDING_USER_AGREEMENT,
    payload: pendingUserAgreement?.data?.uuid ? pendingUserAgreement?.data : null,
  });
};

export const acceptUserAgreement = (userAgreementUuid) => async (dispatch) => {
  try {
    await acceptUserAgreementApi(await getJWT(), userAgreementUuid);
    dispatch(checkSession());
  } catch (err) {
    return dispatch(showError());
  }
};

export const showAcceptedUserAgreement = () => async (dispatch) => {
  let acceptedUserAgreement;
  try {
    acceptedUserAgreement = await fetchAcceptedUserAgreement(await getJWT());
  } catch (err) {
    return dispatch(showError());
  }

  return dispatch({
    type: SET_ACCEPTED_USER_AGREEMENT,
    payload: acceptedUserAgreement?.data?.uuid ? acceptedUserAgreement?.data : null,
  });
};

export const hideAcceptedUserAgreement = () => async (dispatch) => {
  return dispatch({
    type: SET_ACCEPTED_USER_AGREEMENT,
    payload: null,
  });
};

export const checkForPendingUserOrientation = () => async (dispatch, getState) => {
  let isShowingUserAgreement = getState().application.pendingUserAgreement !== null;
  if (isShowingUserAgreement) {
    return;
  }

  if (window.localStorage.getItem(HIDE_USER_ORIENTATION)) {
   return; 
  }

  return await dispatch(showUserOrientationPopup());
};

export const showUserOrientationPopup = () => async (dispatch) => {
  let entries = null;
  try {
    entries = await getContentfulEntries(configuration.contentful.userOrientationTypeId);
  } catch(err) {
    return dispatch(showError());
  }

  return dispatch(setUserOrientationItems(entries?.items || null));
};

export const setUserOrientationItems = (items) => async (dispatch) => {
  return await dispatch({
    type: SET_USER_ORIENTATION_ITEMS,
    payload: items,
  });
};

export const hideUserOrientationPopup = () => async (dispatch) => {
  window.localStorage.setItem(HIDE_USER_ORIENTATION, true);
  return dispatch(setUserOrientationItems(null));
};

export const fetchHowToVideosContent= () => async (dispatch) => {
  let entries = null;
  try {
    entries = await getContentfulEntries(configuration.contentful.howToVideosTypeId);
  } catch(err) {
    return dispatch(showError());
  }

  return dispatch({
    type: SET_HOW_TO_VIDEO_ITEMS,
    payload: entries?.items.sort((a, b) => (a?.fields?.order  - b?.fields?.order)) || null,
  });
};
