import React, { Suspense, useEffect, lazy } from 'react';
import { Router, Route, Switch, Redirect, useParams, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { initAnalytics, sendAnalyticsEvent } from 'util/analytics';
import { loginUser, signupUser, checkSession, resetUser } from 'actions/user';
import { setCurrentPlanningArea, resetMapLayerVisibility } from 'actions/planning';
import { setCurrentScenario } from 'actions/scenario';
import { fetchDataSets } from 'actions/dataset';

import {
  SIGNUP_PATH,
  RESET_PATH,
  PROFILE_PATH,
  HOW_TO_VIDEOS_PATH,
  NEW_PLANNING_AREA_PATH,
  NEW_SCENARIO_PATH,
  PLANNING_AREA_PATH,
  SCENARIO_PATH,
  COMPARISON_PATH,
  COMPARISON_SPATIAL_PATH,
  makePlanningAreaPath,
  SPATIAL_SCENARIO_RUN_PATH,
  SPATIAL_SCENARIO_PATH,
} from 'config/routes';
import configuration from 'config';


const SplashAndComponent = lazy(() => import('./components/Splash/splash-and-component'));
const LoginForm = lazy(() => import('./components/Auth/login'));
const SignupForm = lazy(() => import('./components/Auth/signup'));
const ResetForm = lazy(() => import('./components/Auth/reset'));
const MenuLayout = lazy(() => import('./components/MenuLayout'));
const HomePage = lazy(() => import('./components/HomePage'));
const HowToVideosPage = lazy(() => import('./components/HowToVideosPage'));
const ProfilePage = lazy(() => import('./components/ProfilePage'));
const PlanningAreaPage = lazy(() => import('./components/PlanningAreaPage'));
const ScenarioPage = lazy(() => import('./components/ScenarioPage'));
const ScenarioPlanningPage = lazy(() => import('./components/ScenarioPlanningPage'));
const ComparisonPage = lazy(() => import('./components/ComparisonPage'));
const ComparisonSpatialPage = lazy(() => import('./components/ComparisonSpatialPage'));
const SpatialScenarioPage = lazy(() => import('./components/SpatialScenarioPage'));

if (configuration.newrelic.enabled) {
  window.NREUM.loader_config=configuration.newrelic.loader_config
  window.NREUM.info=configuration.newrelic.info
}

initAnalytics();
sendAnalyticsEvent('page_load');

export const history = createBrowserHistory();

export const isValidUuid = (uuid) => /^[a-zA-Z0-9]{32}$/.test(uuid);

const PrivateRoute = ({ children, userAttributes, ...rest }) => (
  <Route
    {...rest}
    exact
    render={() =>
      !!userAttributes ? (
        <MenuLayout userAttributes={userAttributes}>{children}</MenuLayout>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const InPlanningArea = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { planning_area_uuid } = useParams();
  const { planningAreas, currentPlanningArea } = useSelector((state) => state.planning);
  const planningArea = planningAreas.find((pa) => pa.uuid === planning_area_uuid);

  useEffect(() => {
    if (!planningArea) return;
    if (planningArea.uuid === currentPlanningArea?.uuid) return;

    dispatch(setCurrentPlanningArea(planningArea));
  }, [dispatch, planningArea, currentPlanningArea]);

  useEffect(() => {
    if (!planningArea) return;
    if (planningArea.uuid === planning_area_uuid) return;

    history.replace(makePlanningAreaPath(planningArea.uuid));
  }, [planningArea, planning_area_uuid, history]);

  return <>{isValidUuid(planning_area_uuid) ? children : <Redirect to="/" />}</>;
};

const InScenario = ({ children }) => {
  const dispatch = useDispatch();
  const { scenario_uuid } = useParams();
  const { currentPlanningArea } = useSelector((state) => state.planning);
  const planningAreaScenarios = useSelector(
    (state) => state.scenario.byPlanningArea[currentPlanningArea?.uuid] || []
  );
  let scenario = planningAreaScenarios.find((s) => s?.uuid === scenario_uuid);

  // everytime the scenario_uuid changes we should call setCurrentScenario action
  useEffect(() => {
    if (!scenario) return;
    if (!currentPlanningArea) return;
    dispatch(setCurrentScenario(scenario));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario?.uuid, dispatch, scenario_uuid, currentPlanningArea]);

  return <>{isValidUuid(scenario_uuid) ? children : <Redirect to="/" />}</>;
};

const NewPlanningAreaPage = () => {
  const { data_set_uuid } = useParams();
  const dispatch = useDispatch();
  const { currentDataSet } = useSelector((state) => state.dataset);

  useEffect(() => {
    dispatch(resetMapLayerVisibility());
    dispatch(setCurrentPlanningArea(null));
  }, [dispatch]);

  useEffect(() => {
    if (currentDataSet?.uuid === data_set_uuid) return;
    dispatch(fetchDataSets(data_set_uuid));
  }, [dispatch, currentDataSet, data_set_uuid]);

  return (<PlanningAreaPage />);
};

const App = () => {
  const dispatch = useDispatch();
  const { attributes, imgURL, authError, authInProgress, messageSent } = useSelector(
    (state) => state.user
  );

  useEffect(() => dispatch(checkSession()), [attributes, dispatch]);

  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
      <Switch>
        <PrivateRoute userAttributes={attributes} path={NEW_SCENARIO_PATH}>
          <InPlanningArea>
            <ScenarioPage />
          </InPlanningArea>
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={SCENARIO_PATH}>
          <InPlanningArea>
            <InScenario>
              <ScenarioPlanningPage />
            </InScenario>
          </InPlanningArea>
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={SPATIAL_SCENARIO_RUN_PATH}>
          <InPlanningArea>
            <SpatialScenarioPage />
          </InPlanningArea>    
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={SPATIAL_SCENARIO_PATH}>
          <InPlanningArea>
            <InScenario>
              <SpatialScenarioPage />
            </InScenario>
          </InPlanningArea>    
        </PrivateRoute>                
        <PrivateRoute userAttributes={attributes} path={COMPARISON_SPATIAL_PATH}>
          <InPlanningArea>
            <ComparisonSpatialPage />
          </InPlanningArea>
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={COMPARISON_PATH}>
          <InPlanningArea>
            <ComparisonPage />
          </InPlanningArea>
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={NEW_PLANNING_AREA_PATH}>
          <NewPlanningAreaPage />
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={PLANNING_AREA_PATH}>
          <InPlanningArea>
            <PlanningAreaPage />
          </InPlanningArea>
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={PROFILE_PATH}>
          <ProfilePage userAttributes={attributes} imgURL={imgURL} authError={authError} />
        </PrivateRoute>
        <PrivateRoute userAttributes={attributes} path={HOW_TO_VIDEOS_PATH}>
          <HowToVideosPage />
        </PrivateRoute>
        <Route exact path={SIGNUP_PATH}>
          {attributes ? (
            <Redirect to="/" />
          ) : (
            <SplashAndComponent>
              <SignupForm
                onFinish={(values) => dispatch(signupUser(values))}
                authInProgress={authInProgress}
                authError={authError}
              />
            </SplashAndComponent>
          )}
        </Route>
        <Route exact path={RESET_PATH}>
          <div>
            <SplashAndComponent>
              <ResetForm
                onFinish={(values) => dispatch(resetUser(values))}
                authInProgress={authInProgress}
                authError={authError}
                messageSent={messageSent}
              />
            </SplashAndComponent>
          </div>
        </Route>
        <Route exact path={'/'}>
          {attributes && (
            <MenuLayout userAttributes={attributes}>
              <HomePage userAttributes={attributes}/>
            </MenuLayout>
          )}
          {!attributes && (
            <div>
              <SplashAndComponent>
                <LoginForm
                  onFinish={(values) => dispatch(loginUser(values.email, values.password))}
                  authInProgress={authInProgress}
                  authError={authError}
                />
              </SplashAndComponent>
            </div>
          )}
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
