import { listDataSets, searchDataSets } from 'services/api';
import { getJWT } from 'services/auth';
import { showError } from './application';
import { fetchMapLayers } from './planning';

export const SET_DATA_SETS = 'SET_DATA_SETS';
export const SET_CURRENT_DATA_SET = 'SET_CURRENT_DATA_SET';
export const SET_CURRENT_DATA_SET_VERSION = 'SET_CURRENT_DATA_SET_VERSION';
export const SET_GEOGRAPHY_MODAL_VISIBILITY = 'SET_GEOGRAPHY_MODAL_VISIBILITY';

export const dataSetsErrorMessage =
  'An error occurred loading Geographies. Please try refreshing the page.';

export const fetchDataSets = (currentDataSetUuid) => async (dispatch) => {
  let dataSets = [];
  try {
    let response = await listDataSets(await getJWT());
    dataSets = response.data.filter((d) => d?.active_version?.is_active);
  } catch (err) {
    if (err?.response?.status !== 404) {
      return dispatch(showError(dataSetsErrorMessage));
    }
  }
  dispatch({
    type: SET_DATA_SETS,
    payload: dataSets,
  });

  if (typeof currentDataSetUuid !== 'undefined') {
    let currentDataSet = dataSets?.find((d) => d.uuid === currentDataSetUuid) || null;
    return dispatch(setCurrentDataSet(currentDataSet));
  }
};

export const setCurrentDataSet = (dataSet) => async (dispatch) => {
  await dispatch({
    type: SET_CURRENT_DATA_SET,
    payload: dataSet,
  });
  return dispatch(fetchMapLayers());
};

export const setCurrentDataSetVersionUuid = (datasetVersionUuid) => ({
    type: SET_CURRENT_DATA_SET_VERSION,
    payload: datasetVersionUuid,
});

export const setGeographyModalVisible = (flag) => async (dispatch) => {
  if (flag) {
    dispatch(fetchDataSets());
  }
  await dispatch({
    type: SET_GEOGRAPHY_MODAL_VISIBILITY,
    payload: flag,
  });
};

export const setCurrentDataSetByGeometry = (geometry) => async (dispatch) => {
  let dataSets;
  try {
    dataSets = await searchDataSets(await getJWT(), geometry);
  } catch (err) {
    if (err?.response?.status !== 404) {
      return dispatch(showError(dataSetsErrorMessage));
    }
  }

  if (dataSets?.data && Array.isArray(dataSets.data) && dataSets.data.length > 0) {
    return dispatch(
      setCurrentDataSet(
        dataSets.data.reduce((a, b) => {
          return Math.max(a.overlap, b.overlap);
        })
      )
    );
  }

  return dispatch(setCurrentDataSet(null));
};
